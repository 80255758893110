import React, {FC, useState} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Banner, Button, Flex, FormFieldAPI, H2, Table, Tile, Title } from '@procore/core-react';
import { Warning } from '@procore/core-icons';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IQueryParams, ISortableHeader } from '../../app/interfaces/ISortableTable';
import { IVendor } from '../../app/interfaces/IVendor';
import { getCompanyVendorsThunk, getProjectVendorsThunk, selectVendors, selectVendorsState } from '../../slices/vendorsSlice';
import { StarSize } from '../stars/VendorRatingStars';
import SortableTable from '../tables/SortableTable';
import { ProcoreEmptyCell, ProcoreGenericCell, ProcoreLinkCell, ProcoreTextCell } from '../tables/ProcoreTableCells';
import { ProcoreVendorRatingStars } from '../stars/ProcoreVendorRatingStars';
import styled from 'styled-components';
import { StyledProcoreTile, StyledTileActions } from '../CommonStyledComponents';

const Vendors: FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const companyId: number = parseInt(params.companyId!);
    const maybeProjectId: number | null = params.projectId ? parseInt(params.projectId!) : null;
    const { queryState } = useAppSelector(selectVendorsState);
    const [displayAuthMessage, setDisplayAuthMessage] = useState<boolean>(false);

    // This file supports the company vendors page AND the project vendors page.
    // If we have a project id as a param we're on the project page.
    // Which page we're on will dictate which query we use, etc.
    const vendorsPage: 'company' | 'project' = maybeProjectId && !isNaN(maybeProjectId) ? 'project' : 'company';

    const rateVendor = (vendor: IVendor) => {
        const ratingUrl = maybeProjectId ?
            `/companies/${companyId}/projects/${maybeProjectId}/vendors/${vendor.id}/rating` :
            `/companies/${companyId}/vendors/${vendor.id}/rating`;

        navigate(ratingUrl);
    };

    const columnHeaders: ISortableHeader[] = [
        { value: 'name', key: 'name', text: 'Vendor', sortable: true },
        { value: 'city', key: 'city', text: 'City', sortable: true },
        { value: 'state_code', key: 'state_code', text: 'State', sortable: true },
        { value: 'average_rating', key: 'average_rating', text: 'Rating', sortable: true },
        { value: 'total_ratings', key: 'total_ratings', text: '# Ratings', sortable: true },
        { value: 'ratings', key: 'ratings', text: 'Rate', sortable: false }
    ];

    const queryVendors = (queryParams?: IQueryParams) => {
        if (vendorsPage === 'project') {
            dispatch(getProjectVendorsThunk({ companyId: companyId, projectId: maybeProjectId!, queryParams }));
        } else if (vendorsPage === 'company') {
            dispatch(getCompanyVendorsThunk({ companyId: companyId, queryParams }));
        }
    };

    const renderCard = (dataItem: any) => {
        const v = dataItem as IVendor;
        const fakeField = {
            input: {
              value: v.average_rating
            },
            meta: {
              disabled: true
            },
            helpers: { }
          } as FormFieldAPI<number>;

        return (
            <StyledProcoreTile orientation='portrait' key={v.id}>
                <Tile.Content>
                    <Title>
                        <Title.Text>
                            <H2>
                                <Link to={`/companies/${companyId}/vendors/${v.id}`} >
                                    {v.name}
                                </Link>
                            </H2>
                        </Title.Text>
                        { 
                            (v.city && v.state_code) &&
                                <Title.Subtext>{v.city}, {v.state_code}</Title.Subtext>
                        }
                    </Title>


                    <Flex>
                        <ProcoreVendorRatingStars
                            size={StarSize.Medium}
                            field={fakeField}
                        />

                        {
                            v.total_ratings > 0 &&
                            <div>{v.total_ratings} rating(s)</div>
                        }
                    </Flex>

                    </Tile.Content>
                    <StyledTileActions>
                        <Button block onClick={() => rateVendor(v)} >
                            Rate this Vendor
                        </Button>
                    </StyledTileActions>
            </StyledProcoreTile>
        )
    };

    const renderVendorRow = (dataItem: any) => {
        const v = dataItem as IVendor;
        const fakeField = {
            input: {
              value: v.average_rating
            },
            meta: {
              disabled: true
            },
            helpers: { }
          } as FormFieldAPI<number>;

        return (
            <Table.BodyRow key={v.id}>
                <ProcoreLinkCell to={`/companies/${companyId}/vendors/${v.id}`} cta={v.name} />
                <ProcoreTextCell text={v.city} />
                <ProcoreTextCell text={v.state_code} />

                {
                    v.average_rating ?
                        <ProcoreGenericCell>
                            <ProcoreVendorRatingStars
                                displayNumericalValue={false}
                                size={StarSize.Medium}
                                field={fakeField}
                            />
                        </ProcoreGenericCell>
                        :
                        <ProcoreEmptyCell />
                }

                {
                    v.total_ratings > 0 ?
                        <ProcoreTextCell text={v.total_ratings.toString()} /> :
                        <ProcoreEmptyCell />
                }

                <ProcoreGenericCell>
                    <Button size='sm' onClick={() => rateVendor(v)} >
                        Rate this Vendor
                    </Button>
                </ProcoreGenericCell>
            </Table.BodyRow>
        );
    };

    return (
        <div id='vendors'>
            <h1>Vendors</h1>
            {
                displayAuthMessage &&
                <StyledBanner variant='action'>
                    <Banner.Icon icon={<Warning size='lg' />} />
                    <Banner.Content>
                        <Banner.Title>
                            Add the Sub Ratings Service for your company.
                        </Banner.Title>
                        <Banner.Body>
                            It looks like you don't have access to this information yet.
                            In order to gain access to all your company's vendors, add the Sub Ratings Service on your company's Procore App Management page.
                        </Banner.Body>
                    </Banner.Content>
                    <Banner.Action>
                        <Button onClick={() => navigate('/help')}>Additional Info</Button>
                    </Banner.Action>
                    <Banner.Dismiss onClick={() => setDisplayAuthMessage(false)} />
                </StyledBanner>
                
            }
            <SortableTable
                dataSelector={selectVendors}
                headers={columnHeaders}
                renderDataCard={renderCard}
                renderDataRow={renderVendorRow}
                queryState={queryState}
                queryData={queryVendors}
                exportable={true}
            />
        </div>
    );
};

const StyledBanner = styled(Banner)`
    margin-bottom: 16px;
`;

export default Vendors;
