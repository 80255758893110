import { Form, useFormContext } from "@procore/core-react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearRatingFormCommitments, getRatingFormCommitmentsThunk, selectRatingFormCommitments } from "../../slices/commitmentsSlice";
import { useMobileView } from "../../hooks/use-mobile-view";
import { IProcoreRatingFormSelection } from "../../app/interfaces/IProcoreRatingForm";


interface ICommitmentSelectProps {
  companyId: number;
  maybeSelectedCommitment?: IProcoreRatingFormSelection;
  options: IProcoreRatingFormSelection[];
}

/**
 * This is a select input for the commitment field on the vendor rating form.
 * The options for this dropdown rely on the project field. Hence the use of formContext here.
 */
export const CommitmentSelect = ({companyId, maybeSelectedCommitment, options}: ICommitmentSelectProps) => {
  const dispatch = useAppDispatch();
  const context = useFormContext();
  const commitments = useAppSelector(selectRatingFormCommitments);
  const isMobile = useMobileView();

  useEffect(() => {
    if (context.values?.project && commitments[0]?.project?.id !== context.values.project.id) {
      dispatch(getRatingFormCommitmentsThunk({ companyId, projectId: context.values.project.id }));
      context.setFieldValue('commitment', null);
    }

    if (!context.values?.project && commitments.length > 0) {
      dispatch(clearRatingFormCommitments());
      context.setFieldValue('commitment', null);
    }
  }, [context.values.project]);

  if (!context.values.project || !commitments?.length) {
    return null;
  }
  
  return (
    <Form.Row>
      <Form.Select
        view={ maybeSelectedCommitment ? 'read' : 'create'}
        colWidth={isMobile ? 12 : 6}
        label='Commitment'
        name='commitment'
        options={options}
      />
  </Form.Row>
  );
};
