import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { login, logout } from "../app/apis/subRatingsAPI";
import { IErrorState } from "../app/interfaces/IApiResponses";
import { RootState } from "../app/store";

export interface LoginState {
    status: 'idle' | 'loading' | 'failed';
    error: undefined | IErrorState;
}

const initialState: LoginState = {
    status: 'idle',
    error: undefined
};

export const loginThunk = createAsyncThunk(
    'user/loginWithCode',
    async (code: string, { rejectWithValue }) => {
        try {
            return await login(code);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const logoutThunk = createAsyncThunk(
    'user/logout',
    async (arg: undefined, { rejectWithValue }) => {
        try {
            return await logout();
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        clearLoginError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginThunk.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(loginThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;            })
            .addCase(logoutThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(logoutThunk.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(logoutThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            });
    }
});

export const { clearLoginError } = loginSlice.actions;

export const selectLoginStatus = (state: RootState) => state.login.status;
export const selectLoginState = (state: RootState) => state.login;

export default loginSlice.reducer;
