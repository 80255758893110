import { Outlet } from "react-router-dom";
import { Container } from "semantic-ui-react";
import styled from "styled-components";
import ErrorModal from "../errors/ErrorModal";
import HeaderNavContainer from "../headerNav/HeaderNavContainer";
import Loading from "../loading/Loading";
import NavBreadCrumbs from "../navBreadCrumbs/NavBreadCrumbs";

const AppLayout = () => {

    return (
        <>
            <HeaderNavContainer />
            <Loading />
            <ErrorModal />
            <StyledMainContainer className="main">
                <NavBreadCrumbs />
                <Outlet />
            </StyledMainContainer>
        </>
    )
};

const StyledMainContainer = styled(Container)`
  &&& {
    padding: 15px 0 55px;
  }
`;

export default AppLayout;
