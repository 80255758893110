import { useEffect } from "react";
import { selectUserState } from "../../slices/userSlice";
import { useAppSelector } from "./../../app/hooks";
import { chooseEmbeddedDestination } from '../../utils/User';
import { useNavigate } from "react-router-dom";

/**
 * This page will direct the user to
 * a) the correct page if they're logged in, or
 * b) the login flow if they're not logged in.
 */
export const EmbedLanding = () => {
    const navigate = useNavigate();
    const { data } = useAppSelector(selectUserState);

    useEffect(() => {
        if (!!data) {
            // If the user is logged in, we need to redirect them to the correct page.
            // If we have info from Procore, use that.
            const embeddedDestination = chooseEmbeddedDestination();

            // If we don't have info from Procore, use standard company based flow.
            const companyBasedDestination = data.companies.length > 1 ? '/companies' : `/companies/${data.companies[0].id}`;
            
            const postLoginRedirect = embeddedDestination || companyBasedDestination;
            navigate(postLoginRedirect);
        }

        // TODO: If we can handle the auto-login issues, add that here.
    }, [data]);

    return <h1>Checking your procore login status...</h1>;
};
