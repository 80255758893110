interface IProcoreContext {
  company_id: number;
  id: string;
  project_id: number;
  view: string;
}

export const chooseSidePanelDestination = (context: IProcoreContext) => {
  const destinationOfLastResort = `/`;
  
  switch (context.view) {
    case 'commitments.work_order_contracts.detail':
      if (context.company_id && context.project_id && context.id) {
        return `/companies/${context.company_id}/projects/${context.project_id}/commitments/${context.id}`;
      } else {
        console.error('Missing context for commitments.work_order_contracts.detail');
        return destinationOfLastResort;
      }
    case 'project_directory.vendors.edit':
      if (context.company_id && context.project_id && context.id) {
        const url = `/companies/${context.company_id}/projects/${context.project_id}/vendors/${context.id}/rating`;
        return url;
      }
      else if (context.company_id && context.id) {
        return `/companies/${context.company_id}/vendors/${context.id}/rating`;
      } else {
        return destinationOfLastResort;
      }
    default:
      console.warn(`Unsupported view: ${context.view}`);
      return destinationOfLastResort;
  }

}
