import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IProject } from '../../app/interfaces/IProject';
import { IQueryParams, ISortableHeader } from '../../app/interfaces/ISortableTable';
import { selectCompanies } from '../../slices/companiesSlice';
import { getCompanyProjectsThunk, selectProjects, selectProjectsState } from '../../slices/projectsSlice';
import SortableTable from '../tables/SortableTable';
import { H2, Table, Tile, Title } from '@procore/core-react';
import { ProcoreLinkCell, ProcoreTextCell } from '../tables/ProcoreTableCells';
import { StyledProcoreTile, StyledTileActions } from '../CommonStyledComponents';


const Projects: FC = () => {
    const dispatch = useAppDispatch();
    const params= useParams();
    const companyId = parseInt(params?.companyId!);
    const company = useAppSelector(selectCompanies).find(c => c.id === companyId);

    // TODO: Delete this
    const { queryState } = useAppSelector(selectProjectsState);

    const columnHeaders: ISortableHeader[] = [
        { value: 'project_number', key: 'projectNumber', text: 'Project #', sortable: true },
        { value: 'name', key: 'name', text: 'Project Name', sortable: true },
        { value: 'project_stage', key: 'projectStage', text: 'Stage', sortable: true },
        { value: 'commitments', key: 'commitments', text: 'Commitments', sortable: false },
        { value: 'vendors', key: 'vendors', text: 'Vendors', sortable: false }
    ];

    const queryProjects = (queryParams?: IQueryParams) => 
        dispatch(getCompanyProjectsThunk({companyId, queryParams}));

    // Display same content as renderProjectRow but not in a table row
    const renderCard = (dataItem: any) => {
        const p = dataItem as IProject;
        return (
            <StyledProcoreTile orientation="portrait">
                <Tile.Content>
                    <Title>
                        <Title.Text>
                            <H2>
                                <Link to={`/companies/${companyId}/projects/${p.id}`} >
                                    {p.name}
                                </Link>
                            </H2>
                        </Title.Text>
                        <Title.Subtext>
                            {p.projectNumber}
                        </Title.Subtext>
                    </Title>

                    {
                        p.projectStage &&
                        <div>{p.projectStage} stage</div>
                    }
                </Tile.Content>
                <StyledTileActions>
                        <Link to={`/companies/${companyId}/projects/${p.id}/commitments`} >
                            Commitments
                        </Link> |
                        <Link to={`/companies/${companyId}/projects/${p.id}/vendors`} >
                            Vendors
                        </Link>
                    </StyledTileActions>
            </StyledProcoreTile>
        );
    };

    const renderProjectRow = (dataItem: any) => {
        const p = dataItem as IProject;
        return (
                    <React.Fragment key={p.id} >
                        <Table.BodyRow  >
                            <ProcoreTextCell text={p.projectNumber} />
                            <ProcoreLinkCell to={`/companies/${companyId}/projects/${p.id}`} cta={p.name} />
                            <ProcoreTextCell text={p.projectStage} />
                            <ProcoreLinkCell to={`/companies/${companyId}/projects/${p.id}/commitments`} cta='Commitments' />
                            <ProcoreLinkCell to={`/companies/${companyId}/projects/${p.id}/vendors`} cta='Vendors' />
                        </Table.BodyRow>
                    </React.Fragment>
        );
    };

    if (!company) {
        console.error(`Cannot find company with id ${companyId}`);
        return null;
    }

    return (
        <div id="projects">
            <h1>Projects for {company.name}</h1>
            <SortableTable
                dataSelector={selectProjects}
                headers={columnHeaders}
                renderDataCard={renderCard}
                renderDataRow={renderProjectRow}
                queryState={queryState}
                queryData={queryProjects}
                exportable={true}
            />
        </div>
    );
};

export default Projects;