import React, {FC, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectCompanies } from '../../slices/companiesSlice';
import { getCompanyProjectThunk, selectProject } from '../../slices/projectSlice';
import { StyledLi, StyledUl } from '../CommonStyledComponents';

const Projects: FC = () => {
    const dispatch = useAppDispatch();
    const params= useParams();
    const companyId = parseInt(params?.companyId!);
    const company = useAppSelector(selectCompanies).find(c => c.id === companyId);
    const projectId = parseInt(params?.projectId!);
    const project = useAppSelector(selectProject);

    useEffect(() => {
        dispatch(getCompanyProjectThunk({companyId, projectId}))
    }, [])

    if (!company) {
        return null;
    }

    if (!project) {
        return null;
    }

    return (
        <div id="project">
            <h1>{project.name}</h1>
            <StyledUl>
                <StyledLi>
                    <Link to={`/companies/${companyId}/projects/${project.id}/vendors`}>See Vendors</Link>
                </StyledLi>
                <StyledLi>
                    <Link to={`/companies/${companyId}/projects/${project.id}/commitments`}>See Commitments</Link>
                </StyledLi>
            </StyledUl>
        </div>
    );
};

export default Projects;