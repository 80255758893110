import { AxiosResponseHeaders } from "axios";
import { IQueryParams, IQueryState } from "../app/interfaces/ISortableTable";

export const parseQueryState = (headers: AxiosResponseHeaders, queryParams?: IQueryParams) => {
    const maybePageNumber = parseInt(headers['page']);
    const maybeTotalPagesNumber = parseInt(headers['total_pages']);
    
    const queryState = {
      page: isNaN(maybePageNumber) ? undefined : maybePageNumber,
      totalPages: isNaN(maybeTotalPagesNumber) ? undefined : maybeTotalPagesNumber,
      search: queryParams?.search,
      sort: queryParams?.sort,
      reverse: queryParams?.reverse
    } as IQueryState;

    return queryState;
};