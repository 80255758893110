export enum SubscriptionStatus {
    Active = 'Active',
    Canceled = 'Canceled',
    Expired = 'Expired',
    None = 'None',
    Trial = 'Trial'
}

export interface ICompany {
    id: number;
    name: string;
    subscriptionStatus: SubscriptionStatus;
    trialExpiration: Date;
    hasPaymentMethod: boolean;
    hasServiceAccount: boolean;
    hasCustomCategories: boolean;
}
