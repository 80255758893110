import React from 'react'
import { Button, Modal } from 'semantic-ui-react'

export interface ModalState {
  header: string;
  content: string;
  onContinue: (() => void)
  onClose: (() => void)
}

const CommonModal = (props: ModalState) => {
    // Runs the passed in onContinue function and then closes the modal
    const onContinueAndClose = () => {
      if (props.onContinue) {
        props.onContinue()
      }
      props.onClose()
    };

    return (
      <Modal blurring open closeIcon onClose={() => props.onClose()}>
        <Modal.Header>{props.header}</Modal.Header>
        <Modal.Content>
          {props.content}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => props.onClose()}>
            Close
          </Button>
          <Button positive onClick={() => onContinueAndClose()}>
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
  );
}

export default CommonModal
