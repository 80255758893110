import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IErrorState } from "../app/interfaces/IApiResponses";
import { getQuerySettings, setQuerySettings } from "../app/apis/subRatingsAPI";
import { AxiosError } from "axios";
import { RootState } from "../app/store";

export interface IQuerySettings {
  showInactiveProjects: boolean;
  showTestProjects: boolean;
}

export interface ISetQuerySettingsRequest {
  showInactiveProjects?: boolean;
  showTestProjects?: boolean;
}

export interface IQuerySettingsState {
  status: 'idle' | 'loading' | 'failed';
  data?: IQuerySettings;
  error: undefined | IErrorState;
}

const initialState: IQuerySettingsState = {
  status: 'idle',
  data: undefined,
  error: undefined
}

export const getQuerySettingsThunk = createAsyncThunk(
  'querySettings/getQuerySettings',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await getQuerySettings(companyId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
    }
  }
);

export const setQuerySettingsThunk = createAsyncThunk(
  'querySettings/setQuerySettings',
  async (
    { companyId, querySettings }: { companyId: number, querySettings: ISetQuerySettingsRequest },
    { rejectWithValue }
  ) => {
    try {
      const response = await setQuerySettings(companyId, querySettings);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
    }
  }
);

export const querySettingsSlice = createSlice({
  name: 'querySettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuerySettingsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getQuerySettingsThunk.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload as IQuerySettings;
      })
      .addCase(getQuerySettingsThunk.rejected, (state, action) => {
        state.status = 'failed';
        const errorResponse = action?.payload as IErrorState;
        state.error = errorResponse;
      })
      .addCase(setQuerySettingsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setQuerySettingsThunk.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload as IQuerySettings;
      })
      .addCase(setQuerySettingsThunk.rejected, (state, action) => {
        state.status = 'failed';
        const errorResponse = action?.payload as IErrorState;
        state.error = errorResponse;
      })
  }
});

export const selectQuerySettingsState = (state: RootState) => state.querySettings;
export default querySettingsSlice.reducer;