import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Button, Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppDispatch } from '../../app/hooks';
import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory"
import { deleteCustomCategoryThunk, editCustomCategoryThunk } from '../../slices/customCategoriesSlice';
import { StyledRequiredAsterisk, StyledSegment } from "../CommonStyledComponents"
import CustomRatingCategoryForm from './CustomRatingCategoryForm';


export const CustomRatingCategory = (category: ICustomRatingCategory) => {
    const dispatch = useAppDispatch();
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const params = useParams();
    const companyId = parseInt(params?.companyId!);

    const editCategory = (category: ICustomRatingCategory) => {
        dispatch(editCustomCategoryThunk({companyId, category}));
        setInEditMode(false);
    };

    const deleteCategory = () => {
        dispatch(deleteCustomCategoryThunk({companyId, categoryId: category.id}));
    };
 
    return (
        <>
            {
                inEditMode ?
                    <CustomRatingCategoryForm
                        save={editCategory}
                        cancel={()=> setInEditMode(false)}
                        category={category}   
                    />
                    :
                    <CategoriesStyledSegment>
                        <p>
                            {category.name}  {category.required && <StyledRequiredAsterisk size='tiny' name='asterisk' />}
                            {
                            category.isInUse &&
                                <Popup
                                    content='This category is already in use. It can no longer be deleted.'
                                    trigger={
                                        <Icon name='lock' size='small' />
                                    }
                                />
                            }
                        </p>
                        <div>
                            {
                                !category.isInUse &&
                                    <Button
                                        floated="right"
                                        size="tiny"
                                        icon="trash"
                                        onClick={() => deleteCategory()}
                                    />
                            }
                            <Button
                                floated="right"
                                size="tiny"
                                icon="edit"
                                onClick={() => setInEditMode(true)}
                            />
                        </div>
                    </CategoriesStyledSegment>
            }
        </>
    );
};

const CategoriesStyledSegment = styled(StyledSegment)`
    min-height: 62px;
`