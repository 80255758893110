import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { SubscriptionStatus } from '../../app/interfaces/ICompany';
import { activateTrialThunk, checkoutThunk, selectCompanies, cancelCompanySubscriptionThunk } from '../../slices/companiesSlice';
import CommonModal from '../modal/Modal';

const CompanyAdmin = () => {
    const dispatch = useAppDispatch();
    const params= useParams();
    const companyId = parseInt(params?.companyId!);
    const company = useAppSelector(selectCompanies).find(c => c.id === companyId);
    const hasSubStatus = company?.subscriptionStatus !== SubscriptionStatus.None;
    const [isModalOpen, setIsModalOpen] = useState(false);

    if (!company) {
        console.error(`Cannot find company with id ${companyId}`);
        return null;
    }

    const options = [
        {
            id: 'activate-trial',
            cta: 'Start Trial',
            action: () =>  dispatch(activateTrialThunk(company.id)),
            valid: !hasSubStatus
        },
        {
            id: 'add-payment',
            cta: 'Add Payment Info',
            action: () => dispatch(checkoutThunk(company.id)),
            valid: hasSubStatus && !company.hasPaymentMethod
        },
        {
            id: 'cancel',
            cta: 'Cancel Subscription',
            action: () => setIsModalOpen(true),
            valid: hasSubStatus
        }
    ];

    return (
        <div id='company-admin'>
           { isModalOpen &&
                <CommonModal 
                    header='Cancel Subscription?'
                    content='This action cannot be reversed.'
                    onContinue={() => dispatch(cancelCompanySubscriptionThunk(company.id))}
                    onClose={() => setIsModalOpen(false)}
                />
           }
            <h3>
                Subscription Status: {company.subscriptionStatus}
            </h3>
            {
                company.subscriptionStatus === SubscriptionStatus.Trial &&
                    <p>Until {company.trialExpiration}</p>
            }
            {
                !company.hasPaymentMethod &&
                    <p><Icon name='exclamation' /> Add Payment Method to keep subscription active.</p>
            }
                <StyledMenu secondary>
                    {
                        options.filter(opt => opt.valid).map(opt => {
                            return (
                                <Menu.Item
                                    id={opt.id}
                                    name={opt.cta}
                                    onClick={opt.action}
                                    as='a'
                                    key={opt.id}
                                />
                            )}
                        )
                    }
                </StyledMenu>
        </div>
    );
};

const StyledMenu = styled(Menu)`
    &&& {
        &&&  .item {
            align-self: flex-start;
            border: none;
            color: ${props => props.theme.colors.linkBlue};
            :first-child {
                margin-left: 0;
                padding-left: 0;
            }
        }
        &&& .active .item {
            color: ${props => props.theme.colors.flare};
            border-bottom-width: 10px;
        }
        &&& .item:hover {
            background: none;
            color: ${props => props.theme.colors.flare};
        }
    }
`;

export default CompanyAdmin;