import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getVendorDetailThunk, selectVendorDetailState } from "../../slices/vendorDetailSlice";
import { useEffect } from "react";
import { Button, H1, Page, Spinner, Toast } from "@procore/core-react";
import { getCustomCategoriesThunk, selectCustomCategoriesState } from "../../slices/customCategoriesSlice";
import { ProcoreCustomCategoryRatingForm } from "./ProcoreCustomCategoryRatingForm";
import { resetVendorRatingStatus, selectVendorRatingStatus } from "../../slices/vendorsSlice";
import styled from "styled-components";
import { ProcoreSimpleRatingForm } from "./ProcoreSimpleRatingForm";
import { StyledMain } from "../CommonStyledComponents";
import { selectRatingFormCommitments } from "../../slices/commitmentsSlice";


export const ProcoreVendorRatingPage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();
  const vendorId = parseInt(params.vendorId!);
  const companyId = parseInt(params.companyId!);
  const maybeCommmitmentId = params.commitmentId ? parseInt(params.commitmentId) : undefined;
  const maybeProjectId = params.projectId ? parseInt(params.projectId) : undefined;

  const { data: customRatingCategories, companyId: customCategoriesCompanyId } = useAppSelector(selectCustomCategoriesState);
  const { vendor } = useAppSelector(selectVendorDetailState);
  const vendorRatingStatus = useAppSelector(selectVendorRatingStatus);
  const commitments = useAppSelector(selectRatingFormCommitments);

  const hasCustomCategories: boolean = customRatingCategories.length > 0;

  /**
   * How can we navigate twice? Won't we be gone before the second navigation?
   * If there is no previous page, back (-1) won't do anything.
   * In that case, we'll just go to the vendor page.
   */
  const navigateAway = () => {
    navigate(-1);
    navigate(`/companies/${companyId}/vendors/${vendorId}`);
  };

  const completeSubmission = () => {
    dispatch(resetVendorRatingStatus());
    navigateAway();
  };

  const projectOptions: { id: number, label: string}[] = vendor?.projects?.length ?
    vendor.projects.map(project => ({id: project.id, label: project.name})) : [];
  const maybeSelectedProject =  maybeProjectId ?
    projectOptions?.find(project => project.id === maybeProjectId) :
    undefined;

  const commitmentOptions: { id: number, label: string}[] = commitments?.length ?
    commitments.map(commitment => ({id: commitment.id, label: commitment.title})) : [];
  const maybeSelectedCommitment =  maybeCommmitmentId ?
    commitmentOptions?.find(commitment => commitment.id === maybeCommmitmentId) :
    undefined;
  

  useEffect(() => {
    // When we first arrive at this page, we want to make sure vendor rating status is reset
    dispatch(resetVendorRatingStatus());
  } ,[]);

  useEffect(() => {
    if (!vendor || vendorId !== vendor.id) {
      dispatch(getVendorDetailThunk({companyId, vendorId}));
    }
    if (!hasCustomCategories || customCategoriesCompanyId !== companyId) {
      dispatch(getCustomCategoriesThunk(companyId));
    }
  }, [vendor, vendorId])

  useEffect(() => {
    let completeSubmissionTimer: ReturnType<typeof setTimeout>;
    if (vendorRatingStatus === 'success') {
      completeSubmissionTimer = setTimeout(() => {
        completeSubmission();
      }, 2500);
    }

    return () => {
      clearTimeout(completeSubmissionTimer);
    }
  }, [vendorRatingStatus]);

  return (
    <Spinner label="Submitting your rating..." loading={vendorRatingStatus === 'loading'}>

      <Page>
          <StyledMain>
          <Page.Header>
            <Page.Title>
              <H1>Rate {vendor?.name}</H1>
            </Page.Title>
          </Page.Header>
        <Page.Body>
        {
          !hasCustomCategories &&
            <ProcoreSimpleRatingForm
              commitmentOptions={commitmentOptions}
              companyId={companyId}
              maybeSelectedCommitment={maybeSelectedCommitment}
              maybeSelectedProject={maybeSelectedProject}
              projectOptions={projectOptions}
              vendorId={vendorId}
              vendorRatingStatus={vendorRatingStatus}
          />
        }
        {
          hasCustomCategories &&
            <ProcoreCustomCategoryRatingForm
              commitmentOptions={commitmentOptions}
              customRatingCategories={customRatingCategories}
              companyId={companyId}
              maybeSelectedCommitment={maybeSelectedCommitment}
              maybeSelectedProject={maybeSelectedProject}
              vendorId={vendorId}
              projectOptions={projectOptions}
              vendorRatingStatus={vendorRatingStatus}
          />
        }

        <StyledCancelButton block variant='secondary' onClick={navigateAway}>Back</StyledCancelButton>
        
        </Page.Body>
        </StyledMain>

      </Page>
      { 
          vendorRatingStatus === 'success'  &&
            <StyledToastContainer>
              <Toast variant='success'>
                Rating Submitted.
              </Toast>
            </StyledToastContainer>
        }
    </Spinner>
  );
};

const StyledToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 24px;
  width: inherit;
`;

const StyledCancelButton = styled(Button)`
  margin-top: 24px;
`;
