import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getProjectCommitments } from "../app/apis/subRatingsAPI";
import { IErrorState, ISearchableResponse } from "../app/interfaces/IApiResponses";
import { ICommitment } from "../app/interfaces/ICommitment";
import { IEntityState } from "../app/interfaces/IEntityState";
import { IQueryParams } from "../app/interfaces/ISortableTable";
import { RootState } from "../app/store";

export interface ICommitmentsSliceState extends IEntityState<Array<ICommitment>> {
    ratingFormCommitments: Array<ICommitment>;
}
 
const initialState: ICommitmentsSliceState = {
    data: [],
    ratingFormCommitments: [],
    queryState: undefined,
    status: 'idle',
    error: undefined
};

export const getProjectCommitmentsThunk = createAsyncThunk(
    'commitments/getProjectCommitments',
    async (
        { companyId, projectId, queryParams }: { companyId: number, projectId: number, queryParams?: IQueryParams },
        { rejectWithValue }
    ) => {
        try {
            return await getProjectCommitments(companyId, projectId, queryParams);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

/**
 * This is a thunk that is used to populate the options for form select for commitments on the vendor rating page.
 * We'll keep it separate from the 'data' field in this slice because
 * it's possible for a user to change their project on the form.
 * In that case, we need to keep both sets of data.
 */
export const getRatingFormCommitmentsThunk = createAsyncThunk(
    'commitments/getFormSelectCommitments',
    async (
        { companyId, projectId }: { companyId: number, projectId: number },
        { rejectWithValue }
    ) => {
        try {
            return await getProjectCommitments(companyId, projectId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const commitmentsSlice = createSlice({
    name: 'commitments',
    initialState,
    reducers: {
        clearCommitmentsError: (state) => {
            state.error = undefined;
        },
        clearRatingFormCommitments: (state) => {
            state.ratingFormCommitments = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectCommitmentsThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getProjectCommitmentsThunk.fulfilled, (state, action) => {
                state.status = 'idle';
                const payload = action.payload as ISearchableResponse<ICommitment[]>;
                state.data = payload.data;
                
                // We'll sync the rating form commitments with the project commitments when we initialize.
                // They will intentionally get out of sync if the user changes their project on the form.
                state.ratingFormCommitments = payload.data;

                state.queryState = payload.queryState;
            })
            .addCase(getProjectCommitmentsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(getRatingFormCommitmentsThunk.pending, (state) => {
                // state.status = 'loading';
                // Make this request in the background without a loading spinner.
            })
            .addCase(getRatingFormCommitmentsThunk.fulfilled, (state, action) => {
                state.status = 'idle';
                const payload = action.payload as ISearchableResponse<ICommitment[]>;
                state.ratingFormCommitments = payload.data;
                state.queryState = payload.queryState;
            })
            .addCase(getRatingFormCommitmentsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
    } 
});

export const { clearCommitmentsError, clearRatingFormCommitments } = commitmentsSlice.actions;

export const selectProjectCommitmentsState = (state: RootState) => state.commitments;
export const selectProjectCommitments = (state: RootState) => state.commitments.data;
export const selectRatingFormCommitments = (state: RootState) => state.commitments.ratingFormCommitments;
export const selectProjectCommitmentsStatus = (state: RootState) => state.commitments.status;

export default commitmentsSlice.reducer;
