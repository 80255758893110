/*
    Lightweight shell around system functions to provide better mock-ability in testing.
*/

export const removeSessionStorageItem = (key: string) => sessionStorage.removeItem(key);

export const getBaseUrl = () => window.origin;

export const getCurrentPath = () => window.location.pathname;

export const getEnvVariable = (key:string):string | undefined => process.env[key];

export const getLocalStorageItem = (key: string): string | null => localStorage.getItem(key);

export const getParams = () => new URLSearchParams(window.location.search);

export const getSessionStorageItem = (key: string): string | null => sessionStorage.getItem(key);

export const genRandom = (): string => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

export const navigateWindow = (url: string): void => {
    window.location.href = url;
};

export const isInIframe = () => window.location !== window.parent.location;

export const setLocalStorageItem = (key: string, value: string): void => localStorage.setItem(key, value);

export const setSessionStorageItem = (key: string, value: string): void => sessionStorage.setItem(key, value);
