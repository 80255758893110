import { useMobileView } from '../../hooks/use-mobile-view';
import MobileHeaderNav from './MobileHeaderNav';
import DesktopHeaderNav from './DesktopHeaderNav';
import { useOauthState } from '../../hooks/use-oauth-state';
import { useUserAdmin } from '../../hooks/use-user-admin';

const HeaderNavContainer = () => {
   
    // These components will always be present so we'll them here
    useOauthState()
    useUserAdmin()

    const isMobile = useMobileView();

    return (
        isMobile ? 
            <MobileHeaderNav /> :
            <DesktopHeaderNav />
    )

};

export default HeaderNavContainer;
