import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landing from './features/landing/Landing';
import Confirmation from './features/confirmation/Confirmation';
import Theme from './Theme';
import Companies from './features/companies/Companies';
import Projects from './features/projects/Projects';
import Vendors from './features/vendors/Vendors';
import { useAppSelector } from './app/hooks';
import { selectUser } from './slices/userSlice';
import NotLoggedIn from './features/notLoggedIn/NotLoggedIn';
import CompanyAdmin from './features/companies/CompanyAdmin';
import Commitments from './features/commitments/Commitments';
import ProjectDetail from './features/projects/ProjectDetail';
import Help from './features/help/Help';
import CompanyDetail from './features/companyDetail/CompanyDetail';
import VendorDetail from './features/vendors/VendorDetail';
import AppLayout from './features/commonComponents/AppLayout';
import { EmbedConfirmation } from './features/EmbedConfirmation/EmbedConfirmation';
import { EmbedLanding } from './features/EmbedLanding/EmbedLanding';
import { SidePanelLanding } from './features/sidePanel/SidePanelLanding';
import { ProcoreVendorRatingPage } from './features/vendors/ProcoreVendorRatingPage';

const App = () => {
  const userData = useAppSelector(selectUser);
  const userGatedRoutes = () => { 
    return (
      <>
        <Route path="companies/:companyId/admin" element={<CompanyAdmin />}/>
        <Route path="companies/:companyId/projects/:projectId/commitments" element={<Commitments />} />
        <Route path="companies/:companyId/projects/:projectId/commitments/:commitmentId/vendors/:vendorId/rating" element={<ProcoreVendorRatingPage />} />
        <Route path="companies/:companyId/projects/:projectId/commitments/:commitmentId/vendors/:vendorId" element={<VendorDetail />} />
        <Route path="companies/:companyId/projects/:projectId/commitments/:commitmentId" element={<VendorDetail />} />
        <Route path="companies/:companyId/projects/:projectId/vendors/:vendorId/rating" element={<ProcoreVendorRatingPage />} />
        <Route path="companies/:companyId/projects/:projectId/vendors/:vendorId" element={<VendorDetail />} />
        <Route path="companies/:companyId/projects/:projectId/vendors" element={<Vendors />} />
        <Route path="companies/:companyId/projects/:projectId" element={<ProjectDetail />} />
        <Route path="companies/:companyId/vendors/:vendorId" element={<VendorDetail />} />
        <Route path="companies/:companyId/vendors/:vendorId/rating" element={<ProcoreVendorRatingPage />} />
        <Route path="companies/:companyId/vendors" element={<Vendors />} />
        <Route path="companies/:companyId/projects" element={<Projects />} />
        <Route path="companies/:companyId" element={<CompanyDetail />} />
        <Route path="companies" element={<Companies />} />
        <Route path="embed/landing" element={<EmbedLanding />} />
        <Route path="side-panel/landing" element={<SidePanelLanding />} />
      </>
    );
  };
  
  const anonymousUserRoutes = () => {
    return (
      <Route path="*" element={<NotLoggedIn />} />
    );
  };

  return (
    <Theme>
      <div className="App">
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route element={<AppLayout />} >
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/help" element={<Help />} />
            <Route path="/embed/confirmation" element={<EmbedConfirmation />} />
            { 
              userData ? 
                userGatedRoutes() : 
                anonymousUserRoutes()
            }
            </Route>
            </Routes>
        </BrowserRouter>
      </div>
    </Theme>
  );
}

export default App;
