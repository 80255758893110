import { useState, useEffect } from "react";
/**
 * determines whether a mobile device is likely being used with screen width.
 * Updates if screen is resized.
 * @returns true if viewing mobile size screen
 */
export const useMobileView = (): Boolean => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    return width <= 768;
}

