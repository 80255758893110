import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

interface IThemeProps {
    children: React.ReactNode
}

const theme = {
  colors: {
    adminBlue: '#6699CC',
    darkBackground: "black",
    darkText: 'black',
    lightBackground: "white",
    lightText: "white",
    linkBlue: '#4183c4',
    flare: 'orange',
    procoreButton: '#ff5100',
    procoreButtonHover: '#e64900',
    procoreButtonText: '#fff',
    procoreButtonMarginTop: '1rem',
    // From #20 Unique and Striking https://www.canva.com/learn/website-color-schemes/ 
    crimsonSky: '#CF6766',
    indigo: '#30415D',
    ultramarine: '#031424',
    lightBlue: '#8EAEBD'
  },
  fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em"
  }
};

const Theme: FC<IThemeProps> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
