import React from 'react';
import ReactFreezeFrame from 'react-freezeframe';
import { Link } from 'react-router-dom';
import { Header, List, Image, Divider } from 'semantic-ui-react'
import { IUser } from '../../app/interfaces/IUser';
import { useUserAdmin } from '../../hooks/use-user-admin';

const Help = () => {
    const user: IUser | null  = useUserAdmin();
    return <div>
        <Header as='h1'>Help/FAQs</Header>
        <Header as='h3'>Start a risk-free 14-day free trial today - no credit card required! { !user && <Link to="/login" >Login with your Procore account</Link> }</Header>
        <p>We are always just an email away at <a href="mailto:hello@subratings.com">hello@subratings.com</a> for any questions or issues. Below you will find helpful tips and some of the most frequently asked questions we hear from our users.</p>
        <List as='ul'>
        <List.Item as='li'><a href="#add-custom-app">Add Sub Ratings as a Custom App to your Procore Account</a></List.Item>
        <List.Item as='li'><a href="#procore-webhooks">Procore Webhooks keep your Sub Ratings Vendors up-to-date</a></List.Item>
        <List.Item as='li'><a href="#pricing">Pricing</a></List.Item>
        <List.Item as='li'><a href="#data-access">Data Access/Privacy</a></List.Item>
        </List>
        <span id='add-custom-app' style={{ display: `block`, position: `relative`, top: `-2.5rem` }}></span>
        <Header as='h2'>Add Sub Ratings to your Procore Account from the Procore Marketplace</Header>
        <p>Follow Procore's steps to install our app <a href="https://marketplace.procore.com/apps/sub-ratings-service" target="_blank" rel="noreferrer">directly from the Marketplace</a>. As part of your free trial, you'll see how the marketplace app enables seamless integration between your Sub Ratings and Procore accounts.</p>
        <p>
            <ReactFreezeFrame 
                alt="Add a Custom App to Procore" 
                width="100%" 
                src="/add_marketplace_app.gif"
                options={{
                    overlay: true,
                    trigger: 'click'
                  }}
                />
        </p>
        <span id='procore-webhooks' style={{ display: `block`, position: `relative`, top: `-2.5rem` }}></span>
        <Header as='h2'>Procore Webhooks keep your Sub Ratings Data up-to-date</Header>
        <p>Sub Ratings utilizes Procore's Webhook feature to listen for changes on your Procore account and keep records up-to-date in near real-time. These Webhooks do not allow Sub Ratings to make changes to Procore records, they are strictly read-only data feeds.</p>
        
        <span id='pricing' style={{ display: `block`, position: `relative`, top: `-2.5rem` }}></span>
        <Header as='h2'>Pricing (Beta)</Header>
        <p>
            While we are in Beta, a Sub Ratings subscription is $100 per month for each account.
        </p>
        <span id='data-access' style={{ display: `block`, position: `relative`, top: `-2.5rem` }}></span>
        <Header as='h2'>Data Access/Privacy</Header>
        <p>
            Sub Ratings will access your Procore data in two ways:
            <List as='ol'>
                <List.Item as='li'>
                    <p>Access through your individual user authentication. When you first log in with your Procore credentials, you'll see this message:</p>
                    <Image src='/authorization_required.png' size='large' />
                    <Divider hidden />
                    <p>This authorization allows Sub Ratings to <em>only interact with what your account has permission to access</em>. If you have very limited permissions, you'll likely see error messages appearing due to those restrictions (especially around the Company Directory). That brings us to the second form of access:</p>
                </List.Item>
                <List.Item as='li'>
                    <p>Access through a Procore <Link to="https://developers.procore.com/documentation/developer-managed-service-accounts">Developer Managed Service Account (DMSA)</Link>. The first two items on this help page describe how you can set up our DMSA, and it provides Sub Ratings with the ability to access Company Directory and Portfolio Projects, so it can query that information for your Company Employees to easily rate any Vendors in your Procore Directory. <b>Note: This will only allow users officially identified as employees on your Procore account to see anything in Sub Ratings.</b> You can find this option by editing a User in your Procore Company Directory. (example below)</p>
                    <Image src='/is_employee_flag.png' size='large' />
                    <p style={{ paddingTop: `1rem` }}>If a non-employee logs in, they will only see the following error message.</p>
                    <Image src='/permission-denied-error.png' size='large' />
                </List.Item>
            </List>
        </p>
    
    </div>;
};

export default Help;