import { Button } from "semantic-ui-react";
import styled from "styled-components";
import empty from '../../empty.svg';
import half from '../../half.svg';
import full from '../../full.svg';
import { FormFieldValueComponentProps } from '@procore/core-react'

export enum StarStatus {
    Empty = 'empty',
    Half = 'half',
    Full = 'full'
}

export enum StarSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large'
}

interface IVendorRatingStarsProps {
    displayNumericalValue?: boolean;
    incrementByWholes?: boolean;
    limit?: number;
    size?: StarSize;
}

export const ProcoreVendorRatingStars = ({
    displayNumericalValue,
    incrementByWholes,
    limit=5,
    size=StarSize.Large,
    field
}: IVendorRatingStarsProps & FormFieldValueComponentProps<number>) => {

    const setValue = field.helpers?.setValue;
    const { value: stars } = field.input;

    const disabled = field.meta.disabled;
    
    const starSizeMap = {
        [StarSize.Small]: 12,
        [StarSize.Medium]: 24,
        [StarSize.Large]: 32,
    };

    const starSvgMap = {
        [StarStatus.Empty]: empty,
        [StarStatus.Half]: half,
        [StarStatus.Full]: full
    };

     /**
     * FANCY TODOs
     * temporily fill stars when in hoverstate
     */
    

    // Create an array to iterate through for each star
    let allStars = new Array(limit).fill(1);

    const numFullStars = Math.floor(stars / 1);
    const hasPartialStar = stars % 1 !== 0;

    if (hasPartialStar) {
        allStars[numFullStars] = .5;
    }

    const handleStarClick = (starLevel: StarStatus, starValue: number) => {

        let newVal;
        if (starLevel === StarStatus.Empty) {
            newVal = incrementByWholes ? starValue : starValue - .5;
        } else if (starLevel === StarStatus.Half) {
           newVal = starValue;
        } else {
            newVal = 0;
        }

        // Only pass a name if it has been given.
        if (setValue) {
            setValue(newVal)
        } else {
            console.error('setValue is not defined');
        }
    };

    return (
        <StyledStarContainer id='vendor-rating-stars'>
            { 
                allStars.map((star, i) => {
                    const starLevel = i < numFullStars ? StarStatus.Full : star < 1 ? StarStatus.Half : StarStatus.Empty;
                    const starSvgSource = starSvgMap[starLevel];
                    const testId = `${starLevel}-star-${i + 1}`;
                    // full star
                    return (
                        <StyledButton type='button' icon key={i} data-testid={testId} disabled={disabled} onClick={() => {handleStarClick(starLevel, i + 1)}}>
                            <img height={`${starSizeMap[size]} px`} src={starSvgSource} alt='star'/> 
                        </StyledButton>
                    );
                })
            }
            { displayNumericalValue && 
                <StyledCurrentValue id='current-value'>({(stars === 0 || isNaN(stars)) ? 0 : stars.toFixed(1)})</StyledCurrentValue>
            }
        </StyledStarContainer>
)}

const StyledButton = styled(Button)`
    &&& {
        all: unset;
        padding: 0 5px 0 0;
        :hover {
            background: none;
            cursor: pointer;
        }
        :focus {
            background: none;
        }
        :disabled {
            cursor: not-allowed !important;
            opacity: 100 !important;
            pointer-events: all !important;
        }
    }
`;

const StyledCurrentValue = styled('div')`
    display: inline-flex;
    vertical-align: super;
`;

const StyledStarContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

 