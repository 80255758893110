import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getCompanyProjects } from "../app/apis/subRatingsAPI";
import { IErrorState, ISearchableResponse } from "../app/interfaces/IApiResponses";
import { IEntityState } from "../app/interfaces/IEntityState";
import { IProject } from "../app/interfaces/IProject";
import { IQueryParams } from "../app/interfaces/ISortableTable";
import { RootState } from "../app/store";

const initialState: IEntityState<IProject[]> = {
    status: 'idle',
    queryState: undefined,
    data: [],
    error: undefined
};

export const getCompanyProjectsThunk = createAsyncThunk(
    'projects/getProjects',
    async (
        {companyId, queryParams}: {companyId: number, page?: number, queryParams?: IQueryParams},
        { rejectWithValue }
    ) => {
        try {
            return await getCompanyProjects(companyId, queryParams);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        clearProjectsError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyProjectsThunk.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getCompanyProjectsThunk.fulfilled, (state, action) => {
                const payload = action.payload as ISearchableResponse<IProject[]>
                state.data = payload.data as IProject[];
                state.queryState = payload.queryState;
                state.status = 'idle';
            })
            .addCase(getCompanyProjectsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
    } 
});

export const { clearProjectsError } = projectsSlice.actions;

export const selectProjectsState = (state: RootState) => state.projects;
export const selectProjectsStatus = (state: RootState) => state.projects.status;
export const selectProjects = (state: RootState) => state.projects.data;
export const getState = (state: RootState) => state
export default projectsSlice.reducer;
