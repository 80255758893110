import React, {FC} from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { login } from '../../utils/User';

const NotLoggedIn: FC<{}> = () => {
    return (
        <StyledContent id="notLoggedIn">
            <h1>Login to access this information.</h1>
            <StyledButton onClick={login} variant="outlined">Login With Procore</StyledButton>
        </StyledContent>
    );
};

const StyledButton = styled(Button)`
    &&&& {
        background: ${props => props.theme.colors.procoreButton};
        color: ${props => props.theme.colors.procoreButtonText};
        :hover {
            background: ${props => props.theme.colors.procoreButtonHover};
        }
    }
`;

const StyledContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30%;
    & h1 {
        text-align: center;
    }
`;

export default NotLoggedIn;