import { Dimmer, Loader } from 'semantic-ui-react'
import { useAppSelector } from '../../app/hooks';
import { selectUserStatus } from '../../slices/userSlice';
import { selectCompaniesStatus } from '../../slices/companiesSlice';
import { selectLoginStatus } from '../../slices/loginSlice';
import { selectProjectCommitmentsStatus } from '../../slices/commitmentsSlice';
import { selectVendorStatus } from '../../slices/vendorsSlice';
import { selectProjectsStatus } from '../../slices/projectsSlice';
import { selectProjectStatus } from '../../slices/projectSlice';
import { selectEmailRequestStatus } from '../../slices/emailRequestSlice';

const Loading = () => {
  const userStatus = useAppSelector(selectUserStatus);
  const companiesStatus = useAppSelector(selectCompaniesStatus);
  const commitmentsStatus = useAppSelector(selectProjectCommitmentsStatus);
  const loginStatus = useAppSelector(selectLoginStatus);
  const projectsStatus = useAppSelector(selectProjectsStatus);
  const projectStatus = useAppSelector(selectProjectStatus);
  const vendorsStatus = useAppSelector(selectVendorStatus);
  const emailRequestedState = useAppSelector(selectEmailRequestStatus);
  const globalStatus = [companiesStatus, commitmentsStatus, loginStatus, projectsStatus, projectStatus, userStatus, vendorsStatus,emailRequestedState];

  if (!globalStatus.includes('loading')) {
    return null;
  } 

  return (
    <Dimmer id="loading" active>
      <Loader >Loading</Loader >
    </Dimmer>
  );
};


export default Loading;
