import { Button, Form } from "@procore/core-react";
import { IRatingSubmission } from "../../app/interfaces/IRating";
import { ProcoreVendorRatingStars, StarSize } from "../stars/ProcoreVendorRatingStars";
import { number, object } from "yup";
import { submitVendorRatingThunk } from "../../slices/vendorsSlice";
import { useAppDispatch } from "../../app/hooks";
import { IProcoreRatingFormProps, IProcoreRatingFormSelection, IProcoreRatingFormValues } from "../../app/interfaces/IProcoreRatingForm";
import { CommitmentSelect } from "./CommitmentSelect";
import { useMobileView } from "../../hooks/use-mobile-view";

export const ProcoreSimpleRatingForm = (
    { 
      maybeSelectedCommitment,
      maybeSelectedProject,
      companyId,
      commitmentOptions,
      projectOptions,
      vendorId,
      vendorRatingStatus
    }: IProcoreRatingFormProps
) => {
  const isMobile = useMobileView();
  const formView = vendorRatingStatus === 'idle' ? 'create' : 'read';
  const dispatch = useAppDispatch();
  const yupValidation = {
    rating: number().required('A rating is required')
  };

  const onSubmit = (formValues: IProcoreRatingFormValues) => {
    const comment = formValues.comment ? formValues.comment as string : null;

    const projectId = formValues.project ? (formValues.project as IProcoreRatingFormSelection).id : undefined;
    const commitmentId = formValues.commitment ? (formValues.commitment as IProcoreRatingFormSelection).id : undefined;

    const rating: IRatingSubmission = {
        commitmentId: commitmentId,
        rating: formValues.rating as number,
        customRatings: undefined,
        comment: comment,
        projectId: projectId,
    };

    dispatch(submitVendorRatingThunk({companyId: companyId!, vendorId: vendorId, rating: rating}));
  };

    return (
      <Form
        enableReinitialize={true}
        initialValues={{ commitment: maybeSelectedCommitment, project: maybeSelectedProject }}
        onSubmit={onSubmit}
        validationSchema={object().shape(yupValidation)}
        view={formView}
      >
        <Form.Form>
          {
            projectOptions?.length > 0 &&
            <Form.Row>
              <Form.Select
                colWidth={isMobile ? 12 : 6}
                label='Project'
                name='project'
                options={projectOptions}
                view={ maybeSelectedProject ? 'read' : 'create'}
              />
            </Form.Row>
          }

          <CommitmentSelect
            companyId={companyId}
            maybeSelectedCommitment={maybeSelectedCommitment}
            options={commitmentOptions}
          />

          <Form.Row >
            <Form.Field
              as={ProcoreVendorRatingStars}
              incrementByWholes={false}
              label='Rating'
              name={'rating'}
              required={true}
              size={StarSize.Medium}
              type='button'
            />
          </Form.Row>
          <Form.Row>
            <Form.TextArea name='comment' label='Comment' placeholder='Leave a comment...' colWidth={12}/>
          </Form.Row>
          {
            vendorRatingStatus === 'idle' &&
            <Button type='submit' block>Submit</Button>
          }
        </Form.Form>
      </Form>
    )
};
