import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getCompanyProject } from "../app/apis/subRatingsAPI";
import { IErrorState } from "../app/interfaces/IApiResponses";
import { IProject } from "../app/interfaces/IProject";
import { RootState } from "../app/store";

export interface ProjectState {
    status: 'idle' | 'loading' | 'failed';
    data: IProject | null;
    error: undefined | IErrorState;
}

const initialState: ProjectState = {
    status: 'idle',
    data: null,
    error: undefined
};

export const getCompanyProjectThunk = createAsyncThunk(
    'projects/getProject',
    async (
        { companyId, projectId }: { companyId: number, projectId: number },
        { rejectWithValue }
    ) => {
        try {
            return await getCompanyProject(companyId, projectId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        clearProjectError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyProjectThunk.pending, (state, action) => {
                state.status = 'loading';
            })    
            .addCase(getCompanyProjectThunk.fulfilled, (state, action) => {
                state.data = action.payload as IProject;
                state.status = 'idle';
            })
            .addCase(getCompanyProjectThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
    } 
});

export const { clearProjectError } = projectSlice.actions;

export const selectProject = (state: RootState) => state.project.data;
export const selectProjectStatus = (state: RootState) => state.project.status;
export const selectProjectState = (state: RootState) => state.project;
export const getState = (state: RootState) => state
export default projectSlice.reducer;
