import React, {FC, useEffect} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ICommitment } from '../../app/interfaces/ICommitment';
import { IQueryParams, ISortableHeader } from '../../app/interfaces/ISortableTable';
import { getProjectCommitmentsThunk, selectProjectCommitments, selectProjectCommitmentsState } from '../../slices/commitmentsSlice';
import SortableTable from '../tables/SortableTable';
import { Button, Form, H2, Table, Tile, Title } from '@procore/core-react';
import { ProcoreCurrencyCell, ProcoreGenericCell, ProcoreLinkCell, ProcorePercentCell, ProcoreTextCell } from '../tables/ProcoreTableCells';
import { StyledProcoreTile, StyledTileActions } from '../CommonStyledComponents';

const Commitments: FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const companyId = parseInt(params.companyId!);
    const projectId = parseInt(params.projectId!);
    const navigate = useNavigate();
    const { queryState } = useAppSelector(selectProjectCommitmentsState);
    
    const queryCommitments = (queryParams?: IQueryParams) => {
        dispatch(getProjectCommitmentsThunk({companyId, projectId, queryParams}))
    };

    const renderCommmitmentCard = (dataItem: any) => {
        const commitment = dataItem as ICommitment;

        return (
            <React.Fragment key={commitment.id}>
                <StyledProcoreTile orientation="portrait">
                    <Tile.Content>
                        <Title>
                            <Title.Text>
                                <H2>{commitment.title}</H2>
                            </Title.Text>
                            <Title.Subtext>
                                {commitment.number}
                            </Title.Subtext>
                        </Title>
                        
                        {
                            commitment.vendor &&
                            <Link id={`${commitment.vendor.id}-link`} to={`/companies/${companyId}/vendors/${commitment.vendor.id}`}>
                                {commitment.vendor.company}
                            </Link>
                        }

                        <Form 
                            initialValues={{
                                vendor: commitment.vendor?.company,
                                contractAmount: commitment.grand_total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                                percentPaid: parseFloat(commitment.percentage_paid).toFixed(2),
                                status: commitment.status
                            }}
                            view='read'
                        >
                            <Form.Row>
                                <Form.Currency label="Contract Amount" name="contractAmount" />
                            </Form.Row>
                            <Form.Row>
                                <Form.Number label="Paid" name="percentPaid" decimalScale={2} prefix='%' />
                            </Form.Row>

                        </Form>

                    </Tile.Content>
                    
                    <StyledTileActions>
                        {
                            commitment.vendor &&
                            <Button block size='sm' onClick={() => navigate(`/companies/${companyId}/projects/${projectId}/commitments/${commitment.id}/vendors/${commitment.vendor.id}/rating`)} >
                                Rate{commitment.vendor.company}
                            </Button>
                        }
                    </StyledTileActions>

                </StyledProcoreTile>
            </React.Fragment >
        )
    };


    const columnHeaders: ISortableHeader[] = [
        { value: 'number', key: 'number', text: 'Contract #', sortable: true },
        { value: 'company', key: 'company', text: 'Vendor Name', sortable: true },
        { value: 'title', key: 'title', text: 'Contract Title', sortable: true },
        { value: 'grand_total', key: 'grand_total', text: 'Contract Amount', sortable: true },
        { value: 'percentage_paid', key: 'percentage_paid', text: '% Paid', sortable: true },
        { value: 'status', key: 'status', text: 'Contract Status', sortable: true },
        { value: 'ratings', key: 'ratings', text: 'Ratings', sortable: false }
    ];

    const renderCommitmentRow = (dataItem: any) => {
        const commitment = dataItem as ICommitment;
        return (
            <Table.BodyRow key={`${commitment.id}`} >
                <ProcoreTextCell text={commitment.number} />

                {
                    commitment.vendor ?
                        <ProcoreLinkCell to={`/companies/${companyId}/vendors/${commitment.vendor.id}`} cta={commitment.vendor.company} /> :
                        <ProcoreTextCell text='N/A' />
                }

                <ProcoreTextCell text={commitment.title} />
                
                <ProcoreCurrencyCell
                    amount={'$' + commitment.grand_total.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                />
                    
                <ProcorePercentCell
                    amount={parseFloat(commitment.percentage_paid).toFixed(2)}
                />

                <ProcoreTextCell text={commitment.status} />

                {
                    commitment.vendor ?
                    <ProcoreGenericCell>
                        <Button size='sm' onClick={() => navigate(`/companies/${companyId}/projects/${projectId}/commitments/${commitment.id}/vendors/${commitment.vendor.id}/rating`)} >
                            Rate this Vendor
                        </Button>
                    </ProcoreGenericCell> :
                    <ProcoreTextCell text='N/A' />
                }
            </Table.BodyRow>
        );
    };

    useEffect(() => {
        if ( projectId ) {
            dispatch(getProjectCommitmentsThunk({companyId, projectId}))
        }
    }, [])


    return (
        <div id="commitments">
            <h1>Commitments</h1>
            <SortableTable 
                dataSelector={selectProjectCommitments}
                headers={columnHeaders}
                renderDataCard={renderCommmitmentCard}
                renderDataRow={renderCommitmentRow}
                queryData={queryCommitments}
                queryState={queryState}
            />
        </div>
    );
};

export default Commitments;