import { Button } from "semantic-ui-react";
import styled from "styled-components";
import empty from '../../empty.svg';
import half from '../../half.svg';
import full from '../../full.svg';

export enum StarStatus {
    Empty = 'empty',
    Half = 'half',
    Full = 'full'
}

export enum StarSize {
    Small = 'small',
    Medium = 'medium',
    Large = 'large'
}

interface IVendorRatingStarsProps {
    id?: number;
    disabled?: boolean,
    displayNumericalValue?: boolean;
    incrementByWholes?: boolean;
    limit?: number,
    size?: StarSize
    stars: number,
    updateStars?: (newStarVal: number, id?: number) => void

}

const VendorRatingStars = ({
    disabled=false,
    displayNumericalValue,
    incrementByWholes,
    limit=5,
    id,
    size = StarSize.Large,
    stars,
    updateStars,
}: IVendorRatingStarsProps) => {
    const starSizeMap = {
        [StarSize.Small]: 15,
        [StarSize.Medium]: 30,
        [StarSize.Large]: 45,
    };

    const starSvgMap = {
        [StarStatus.Empty]: empty,
        [StarStatus.Half]: half,
        [StarStatus.Full]: full
    };

     /**
     * FANCY TODOs
     * temporily fill stars when in hoverstate
     */
    

    // Create an array to iterate through for each star
    let allStars = new Array(limit).fill(1);

    const numFullStars = Math.floor(stars / 1);
    const hasPartialStar = stars % 1 !== 0;

    if (hasPartialStar) {
        allStars[numFullStars] = .5;
    }

    const handleStarClick = (starLevel: StarStatus, starValue: number) => {
        if (!updateStars) {
            console.warn('A star was clicked but no function was passed in to update the star. Either add an updateStars function or disable the component.');
            return;
        }

        let newVal;
        if (starLevel === StarStatus.Empty) {
            newVal = incrementByWholes ? starValue : starValue - .5;
        } else if (starLevel === StarStatus.Half) {
           newVal = starValue;
        } else {
            newVal = 0;
        }

        // Only pass a name if it has been given.
        id ? updateStars(newVal, id) : updateStars(newVal);
    };

    return (
        <div id='vendor-rating-stars'>
            { 
                allStars.map((star, i) => {
                    const starLevel = i < numFullStars ? StarStatus.Full : star < 1 ? StarStatus.Half : StarStatus.Empty;
                    const starSvgSource = starSvgMap[starLevel];
                    const testId = `${starLevel}-star-${i + 1}`;
                    // full star
                    return (
                        <StyledButton icon key={i} data-testid={testId} disabled={disabled} onClick={() => {handleStarClick(starLevel, i + 1)}}>
                            <img height={`${starSizeMap[size]} px`} src={starSvgSource} alt='star'/> 
                        </StyledButton>
                    );
                })
            }
            { displayNumericalValue && <StyledCurrentValue id='current-value'>({(stars === 0 || isNaN(stars)) ? 0 : stars.toFixed(1)})</StyledCurrentValue> }
        </div>
)}

const StyledButton = styled(Button)`
    &&& {
        all: unset;
        padding: 0 5px 0 0;
        :hover {
            background: none;
            cursor: pointer;
        }
        :focus {
            background: none;
        }
        :disabled {
            cursor: not-allowed !important;
            opacity: 100 !important;
            pointer-events: all !important;
        }
    }
`;

const StyledCurrentValue = styled('div')`
    display: inline-flex;
    vertical-align: super;
`;

export default VendorRatingStars;
