import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { createCustomCategory, deleteCustomCategory, editCustomCategory, getCustomCategories, reprioritizeCustomCategory } from "../app/apis/subRatingsAPI";
import { ICreateCustomCategoryResponse, IErrorState, IGetCustomCategoriesResponse } from "../app/interfaces/IApiResponses";
import { ICustomRatingCategory } from "../app/interfaces/ICustomRatingCategory";
import { RootState } from "../app/store";

export interface ICustomCategoriesSlice {
    status: 'idle' | 'loading' | 'failed';
    companyId: undefined | number;
    data: Array<ICustomRatingCategory>;
    creatingCategory: boolean;
    error: undefined | IErrorState;
}

const initialState: ICustomCategoriesSlice = {
    status: 'idle',
    companyId: undefined,
    data: [],
    creatingCategory: false,
    error: undefined,
};

export const getCustomCategoriesThunk = createAsyncThunk(
    'customCategories/getCustomCategories',
    async (companyId: number, { rejectWithValue } ) => {
        try {
            return await getCustomCategories(companyId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const createCustomCategoryThunk = createAsyncThunk(
    'customCategories/createCustomCategories',
    async ({companyId, category }: { companyId: number, category: ICustomRatingCategory }, { rejectWithValue } ) => {
        try {
            return await createCustomCategory(companyId, category);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const deleteCustomCategoryThunk = createAsyncThunk(
    'customCategories/deleteCustomCategories',
    async ({companyId, categoryId }: { companyId: number, categoryId: number }, { rejectWithValue } ) => {
        try {
            return await deleteCustomCategory(companyId, categoryId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const editCustomCategoryThunk = createAsyncThunk(
    'customCategories/editCustomCategories',
    async ({companyId, category }: { companyId: number, category: ICustomRatingCategory }, { rejectWithValue } ) => {
        try {
            return await editCustomCategory(companyId, category);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const reprioritizeCustomCategoriesThunk = createAsyncThunk(
    'customCategories/reprioritizeCustomCategories',
    async ({companyId, categories}: { companyId: number, categories: ICustomRatingCategory[]}, { rejectWithValue } ) => {
        try {
            return await reprioritizeCustomCategory(companyId, categories);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({reason: error.response?.data['reason'], status: error.response?.status});
        }
    }
);

export const customCategoriesSlice = createSlice({
    name: 'customCategories',
    initialState,
    reducers: {
        clearCustomCategoriesError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomCategoriesThunk.fulfilled, (state, action) => {
                const { data, companyId } = action.payload as IGetCustomCategoriesResponse;
                state.companyId = companyId;
                state.data = data;
                state.status = 'idle';
            })
            .addCase(getCustomCategoriesThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(createCustomCategoryThunk.pending, (state) => {
                state.status = 'loading';
                state.creatingCategory = true;
            })
            .addCase(createCustomCategoryThunk.fulfilled, (state, action) => {
                const { data: new_category } = action.payload as ICreateCustomCategoryResponse;
                state.data = [...state.data, new_category];
                state.status = 'idle';
                state.creatingCategory = false;
            })
            .addCase(createCustomCategoryThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
                state.creatingCategory = false;
            })
            .addCase(deleteCustomCategoryThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteCustomCategoryThunk.fulfilled, (state, action) => {
                const { id } = action.payload as { id: number };
                state.data = [...state.data.filter(category => category.id !== id)];
                state.status = 'idle';
            })
            .addCase(deleteCustomCategoryThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(editCustomCategoryThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(editCustomCategoryThunk.fulfilled, (state, action) => {
                const updated = action.payload as ICustomRatingCategory;
                const updatedCategories = [updated, ...state.data.filter(category => category.id !== updated.id)]
                    .sort((a, b) => a.priority - b.priority);
                state.data = updatedCategories;
                state.status = 'idle';
            })
            .addCase(editCustomCategoryThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(reprioritizeCustomCategoriesThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(reprioritizeCustomCategoriesThunk.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(reprioritizeCustomCategoriesThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
    }
});

export const { clearCustomCategoriesError } = customCategoriesSlice.actions;
export const selectCustomCategoriesState = (state: RootState) => state.customCategories;
export default customCategoriesSlice.reducer;
