import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCommitmentVendorThunk, getVendorDetailThunk, selectVendorDetailState } from '../../slices/vendorDetailSlice';
import { VendorRatings } from './VendorRatings';
import { Button } from '@procore/core-react';

const VendorDetail = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();

    // We support multiple routes to this component.
    // We should be able to count on having company id.
    // We'll have either a vendor id, or a project id and commitment id.
    const companyId: number = parseInt(params.companyId!);
    const projectId: number | null = params.projectId ? parseInt(params.projectId): null;
    const commitmentId: number | null = params.commitmentId ? parseInt(params.commitmentId): null;
    const vendorId: number | null = params.vendorId ? parseInt(params.vendorId) : null;

    const { vendor } = useAppSelector(selectVendorDetailState);

    const rateVendor = () => {
        const vendorRatingUrl = (projectId && commitmentId) ?
            `/companies/${companyId}/projects/${projectId}/commitments/${commitmentId}/vendors/${vendor?.id}/rating` :
            projectId ?
                `/companies/${companyId}/projects/${projectId}/vendors/${vendor?.id}/rating` :
                `/companies/${companyId}/vendors/${vendor?.id}/rating`;

        navigate(vendorRatingUrl);
    };

    useEffect(() => {
        if (vendorId) {
            dispatch(getVendorDetailThunk({companyId, vendorId}));
        } else if (projectId && commitmentId) {
         dispatch(getCommitmentVendorThunk({companyId, projectId, commitmentId}));
        } else {
            console.error('VendorDetail component was not provided with a vendorId, or projectId and commitmentId. Path:', window.location.pathname);
        }

    }, [])

    if (!vendor) {
        return null;
    }

    return (
        <>
            <StyledVendorName>{vendor.name}</StyledVendorName>
            {
                vendor.city && vendor.state_code &&
                    <p>{vendor.city}, {vendor.state_code}</p>
            }
            <StyledRatingsSection>
                <StyledHeaderContainer>
                    <h2>
                        Ratings
                    </h2>

                    <Button 
                        onClick={rateVendor}
                        variant='primary'
                    >
                        Rate {vendor.name}
                    </Button>
                </StyledHeaderContainer>


                {vendor.total_ratings > 0 ?
                    <VendorRatings
                        companyId={companyId}
                        vendor={vendor}
                    /> :
                    <p>Vendor does not have any ratings yet.</p>
                }
            </StyledRatingsSection>
        </>
    )
};

const StyledRatingsSection = styled('div')`
    margin: 15px 0;
    && #rating-info {
        margin-bottom: 15px;
    }
`;

const StyledVendorName = styled('h1')`
    margin-top: 0;
`;

const StyledHeaderContainer = styled('div')`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    @media (max-width: 768px) {
        display: block;
    }
}`;

export default VendorDetail;
