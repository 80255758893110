import React, { useState } from 'react';
import { Button, Divider, Grid, GridColumn, GridRow, Icon, Image, Modal } from 'semantic-ui-react'
import styled from 'styled-components';
import { login } from '../../utils/User';
import { IUser } from '../../app/interfaces/IUser';
import { useUserAdmin } from '../../hooks/use-user-admin';
import { useAppSelector } from '../../app/hooks';
import { selectCompanies } from '../../slices/companiesSlice';
import { Link, useNavigate } from 'react-router-dom';
import ratingsFunctionalityScreenshot from '../../assets/images/ratingsFunctionalityScreenshot.png';

const Landing = () => {
    const navigate = useNavigate();
    const user: IUser | null  = useUserAdmin();
    const companies = useAppSelector(selectCompanies);
    const hasSingleCompany = companies ? companies.length === 1 : 0;
    const [screenshotZoomed, setScreenshotZoomed] = useState(false);

    return (
        <>
                <Modal open={screenshotZoomed} closeIcon onClose={() => setScreenshotZoomed(false)} >
                    <Image  src={ratingsFunctionalityScreenshot} />
                </Modal>

                <StyledBanner >
                    <div className='centered-cta'>
                        <h1>Sub Ratings</h1>
                        <h2>Privately &amp; securely rate your subcontractors and vendors in Procore</h2>
                        { 
                            !user && 
                                <>
                                    <Button size='huge' primary onClick={login} >Start a 14-day Free Trial <Icon className='arrow right' /></Button>
                                    <p>Already registered? Log in <StyledButtonAsLink onClick={login}>here.</StyledButtonAsLink></p>
                                </>
                        }
                        
                        {
                            user && hasSingleCompany &&
                            <Button size='huge' primary onClick={() => navigate(`/companies/${companies[0].id}`)} >Get Started <Icon className='arrow right' /></Button>
                        }

                        {
                            user && !hasSingleCompany &&
                            <Button size='huge' primary onClick={() => navigate(`/companies`)} >Get Started <Icon className='arrow right' /></Button>
                        }

                    </div>
                </StyledBanner>
                <StyledLandingContainer>
                    <StyledLandingContent>
                        <StyledGrid container stackable>
                            <GridRow >
                                <Grid.Column width={8}>
                                    <h1>Welcome to Sub Ratings</h1>
                                    <p>Welcome to Sub Ratings, a private, secure rating system for your vendors and subcontractors. This application enhances your bidding, project management, and closeout processes by giving everyone in your company a fast, easy way to rate and review subcontractor relationships across all of your projects.</p>
                                    <p>You can even create your own custom categories for your account, with options to make important categories required for every rating. Give your teams the ability to provide invaluable feedback to everyone across your business, privately and securely.</p>
                                    <p>This is the simplest way to rate your subcontractors and vendors in Procore, and keep that information secure and private in your business. Your company's rating data will never be shared or used outside of your account.</p>
                                    <h1>Ready to get started?</h1>
                                    <p>How do you get set up? We're so glad you asked. Check out our <Link color='white' to='/help'>help page</Link> for more info and start your <b>14-day free trial now</b> (no credit card required).</p>

                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <StyledZoomableImageWrapper onClick={() => setScreenshotZoomed(true)} >
                                        <Image alt='screenshot of ratings functionality' width='700' src={ratingsFunctionalityScreenshot} />
                                    </StyledZoomableImageWrapper>
                                </Grid.Column>
                            </GridRow>
                            <GridRow centered>
                                <Button size='huge' onClick={() => navigate('/help')}>Learn More</Button>
                            </GridRow>
                            <GridRow >
                                <GridColumn width={16}>
                                    <Divider horizontal>Beta Access</Divider>
                                    <h1>Sign up now</h1>
                                    <p>During the beta phase for Sub Ratings, you'll have easy access to its creators to help shape the app to best suit your company's needs.</p>
                                    <Button as='a' href='mailto:hello@subratings.com'>Get In Touch</Button>
                                </GridColumn>
                            </GridRow>
                        </StyledGrid>
                    </StyledLandingContent>
                </StyledLandingContainer>
        </>
    );
}


const StyledBanner = styled('div')`
&&& {
    background-color: black;
    display: flex;
    flex-direction: column;
    height: 700px;
    justify-content: center;
    margin: 0;
    width: 100%;
    & .centered-cta {
        margin: 0 auto;
        text-align: center;
        width: 60%;
        & h1, h2, p {
            color: #fff;
        }
        & h1 {
            font-size: 4em;
        }
        & h2 {
            font-size: 1.7em;
        }
    }
}
`;

const StyledLandingContainer = styled('div')`
    background-color: aliceblue;
`;

const StyledLandingContent = styled('div')`
    margin: 0 auto;
    padding-top: 50px;
    width: 80%;
`;

const StyledGrid = styled(Grid)`
    && {
        & h1 {
            font-size: 2em;
        }
        & .row {
            padding-top: 60px;
        }
    }
`;

const StyledZoomableImageWrapper = styled('div')`
    cursor: zoom-in; 
`;

const StyledButtonAsLink = styled(Button)`
    &&& {
        background: none!important;
        border: none;
        padding: 0!important;
        /*optional*/
        /*input has OS specific font-family*/
        color: ${props => props.theme.colors.flare};
        text-decoration: underline;
        cursor: pointer;
        margin-top: 10px;
    }
`;


export default Landing;
