import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IErrorState } from '../../app/interfaces/IApiResponses';
import { clearCommitmentsError, selectProjectCommitmentsState } from '../../slices/commitmentsSlice';
import { clearCompaniesError, selectCompaniesState } from '../../slices/companiesSlice';
import { clearCustomCategoriesError, selectCustomCategoriesState } from '../../slices/customCategoriesSlice';
import { clearLoginError, selectLoginState } from '../../slices/loginSlice';
import { clearProjectError, selectProjectState } from '../../slices/projectSlice';
import { clearProjectsError, selectProjectsState } from '../../slices/projectsSlice';
import { clearUserError, selectUserState } from '../../slices/userSlice';
import { clearVendorDetailError, selectVendorDetailState } from '../../slices/vendorDetailSlice';
import { clearVendorsError, selectVendorsState } from '../../slices/vendorsSlice';

const ErrorModal = () => {
    const dispatch = useAppDispatch();
    const [currentError, setCurrentError] = useState<undefined | IErrorState>(undefined);
    const { error: vendorsError} = useAppSelector(selectVendorsState);
    const { error: projectsError } = useAppSelector(selectProjectsState);
    const { error: commitmentsError } = useAppSelector(selectProjectCommitmentsState);
    const { error: companiesError } = useAppSelector(selectCompaniesState);
    const { error: loginError } = useAppSelector(selectLoginState);
    const { error: projectError } = useAppSelector(selectProjectState);
    const { error: userError } = useAppSelector(selectUserState);
    const { error: vendorDetailError } = useAppSelector(selectVendorDetailState);
    const { error: customCategoriesError } = useAppSelector(selectCustomCategoriesState);

    const errorStates = [commitmentsError, companiesError, customCategoriesError, loginError, projectsError, userError, vendorsError, vendorDetailError];

    const clearError = () => {
        if (vendorsError) {
            dispatch(clearVendorsError());
            return;
        }

        if (projectsError) {
            dispatch(clearProjectsError());
            return;
        }

        if (commitmentsError) {
            dispatch(clearCommitmentsError());
            return;
        }

        if (companiesError) {
            dispatch(clearCompaniesError())
            return;
        }

        if (loginError) {
            dispatch(clearLoginError());
            return;
        }

        if (projectError) {
            dispatch(clearProjectError());
            return;
        }

        if (userError) {
            dispatch(clearUserError);
            return;
        }

        if (vendorDetailError) {
            dispatch(clearVendorDetailError())
            return;
        }

        if (customCategoriesError) {
            dispatch(clearCustomCategoriesError())
            return;
        }
    }

    useEffect(() => {
        const definedError = errorStates.find(e => e !== undefined);
        if (definedError) {
            setCurrentError(definedError);
        } else {
            setCurrentError(undefined);
        }

    }, errorStates)

    return (
        <StyledModal size='mini' open={!!currentError} closeIcon onClose={clearError}>
            <Modal.Header>Oops! Something has gone wrong.</Modal.Header>
            <Modal.Content>
                { 
                    currentError?.reason ?
                        currentError.reason : 
                        'If the problem persists, please let us know at hello@subratings.com'
                }
            </Modal.Content>
        </StyledModal>
    );
}

const StyledModal = styled(Modal)`
    && {
        & .header {
            background: ${props => props.theme.colors.crimsonSky}
        }
    }
`

export default ErrorModal;
