import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IRating } from "../../app/interfaces/IRating";
import { IQueryParams, ISortableHeader } from "../../app/interfaces/ISortableTable";
import { IVendor } from "../../app/interfaces/IVendor";
import { getVendorRatingsThunk, selectVendorDetailState, ratingsSelector } from "../../slices/vendorDetailSlice";
import VendorRatingStars, { StarSize } from "../stars/VendorRatingStars";
import SortableTable from "../tables/SortableTable";
import styled from "styled-components";
import { Flex, FormFieldAPI, Label, Popover, Table, Tile,  } from "@procore/core-react";
import { Info } from '@procore/core-icons'
import { ProcoreEmptyCell, ProcoreGenericCell, ProcoreLinkCell, ProcoreTextCell } from "../tables/ProcoreTableCells";
import { ProcoreVendorRatingStars } from "../stars/ProcoreVendorRatingStars";
import { VendorRatingsCategories } from "./VendorRatingsCategories";
import { Link } from "react-router-dom";
import { StyledProcoreTile } from "../CommonStyledComponents";

/**
 * 
 * TODO:
 * - fix the star string parsing issue with .fixed()
 */


export const VendorRatings = ({ companyId, vendor }: {companyId: number, vendor: IVendor}) => {
  const dispatch = useAppDispatch();
  const { queryState } = useAppSelector(selectVendorDetailState);

  const queryRatings = (queryParams?: IQueryParams) => {
    const vendorId = vendor.id;
    dispatch(getVendorRatingsThunk({companyId, vendorId, queryParams }));
  };

  const columnHeaders: ISortableHeader[] = [
    { value: 'rating', key: 'rating', text: 'Rating', sortable: true },
    { value: 'createdByEmail', key: 'createdByEmail', text: 'User', sortable: true },
    { value: 'created_date', key: 'created_date', text: 'Date', sortable: true },
    { value: 'description', key: 'description', text: 'Comments', sortable: false },
    { value: 'projectName', key: 'projectId', text: 'Project', sortable: true },
    { value: 'commitmentName', key: 'commitmentProcoreId', text: 'Commitment $', sortable: true },
  ];

  const renderRatingCard = (dataItem: any) => {
    const rating = dataItem as IRating;
    const ratingDate = new Date(rating.createdDate).toLocaleDateString();

    const fakeRatingField = {
        input: {
            value: rating.rating ? rating.rating : 0
        },
        meta: {
            disabled: true
        },
        helpers: { }
    } as FormFieldAPI<number>;
    return (
        <StyledProcoreTile orientation="portrait">
            <Tile.Content>
                <Flex justifyContent="space-between">

                    <div>
                        <ProcoreVendorRatingStars
                            field={fakeRatingField}
                            size={StarSize.Medium}
                        />
                    </div>

                    <div>{ratingDate}</div>
                </Flex>

                {
                    (rating.createdByFirstName || rating.createdByEmail) &&
                        <div>
                            <Label>Reviewer:&nbsp; &nbsp;</Label>
                            {
                                rating.createdByFirstName ?
                                <a href={"mailto:"+rating.createdByEmail}>{rating.createdByFirstName} {rating.createdByLastName}</a> :
                                <a href={"mailto:"+rating.createdByEmail}>{rating.createdByEmail}</a>
                            }
                        </div>

                }



                {
                rating.projectId &&
                <div>
                    <Label>Project:&nbsp; &nbsp;</Label>

                    <Link to={`/companies/${companyId}/projects/${rating.projectId}`} >
                        {rating.projectName || ''}
                    </Link>
                </div>
            }

            {
                rating.commitmentProcoreId &&
                <div>
                    <Label>Commitment:&nbsp;</Label>

                    <Link to={`/companies/${companyId}/projects/${rating.commitmentProcoreId}`}>
                    {`${rating.commitmentName} (${rating.commitmentAmount ? '$' + rating.commitmentAmount!.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 'N/A'})`}
                    </Link>
                </div>

            }

            {
                rating.description &&
                <div>
                    <Label>Comments</Label>
                    <p>{rating.description}</p>
                </div>
            }

            {
                rating.customCategories?.length > 0 &&
                    <Flex gap="16px" wrap='wrap'>
                        <VendorRatingsCategories
                            categories={rating.customCategories} 
                        />
                    </Flex>

            }

            </Tile.Content>
        </StyledProcoreTile>
    );
    
  };

  const renderRatingRow = (dataItem: any) => {
    const rating = dataItem as IRating;
    const ratingDate = new Date(rating.createdDate).toLocaleDateString();
    const fakeRatingField = {
        input: {
            value: rating.rating ? rating.rating : 0
        },
        meta: {
            disabled: true
        },
        helpers: { }
    } as FormFieldAPI<number>;

    return (
        <Table.BodyRow key={`rating-${rating.id}`}>
            <ProcoreGenericCell>
                <ProcoreVendorRatingStars
                    size={StarSize.Medium}
                    field={fakeRatingField}
                />
                {
                    rating.customCategories?.length > 0 &&
                    <Popover
                        key={rating.id}
                        trigger='hover'
                        overlay={
                            <StyledContent>
                                <VendorRatingsCategories
                                    categories={rating.customCategories} 
                                />
                            </StyledContent>
                        }
                    >
                        {() => <Info size='sm' />}
                    </Popover>
                }
            </ProcoreGenericCell>
            <Table.BodyCell>
                <Table.TextCell>
                    {
                        rating.createdByFirstName ?
                        <a href={"mailto:"+rating.createdByEmail}>{rating.createdByFirstName} {rating.createdByLastName}</a> :
                        <a href={"mailto:"+rating.createdByEmail}>{rating.createdByEmail}</a>
                    }
                    {
                        rating.createdByJobTitle ?
                        <span>&nbsp;({rating.createdByJobTitle})</span> : 
                        ''
                    }
                </Table.TextCell>
            </Table.BodyCell>
            <ProcoreTextCell text={ratingDate} />
            <ProcoreTextCell text={rating.description || ''} />
            {
                rating.projectId ?
                <ProcoreLinkCell
                    to={`/companies/${companyId}/projects/${rating.projectId}`}
                    cta={rating.projectName || ''}
                /> :
                <ProcoreEmptyCell />
            }
            {
                rating.commitmentProcoreId ?
                <ProcoreLinkCell
                    to={`/companies/${companyId}/projects/${rating.commitmentProcoreId}`}
                    cta={`${rating.commitmentName} (${rating.commitmentAmount ? '$' + rating.commitmentAmount!.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 'N/A'})`}
                /> :
                <ProcoreEmptyCell />
            }
        </Table.BodyRow>
    );
  }

  return (
      <>
          <div id='rating-info'>
              Average Rating:&nbsp;
              <VendorRatingStars
                  disabled
                  size={StarSize.Large}
                  stars={vendor.average_rating ? vendor.average_rating : 0}
                  displayNumericalValue
              />
              <div>Number of ratings: {vendor.total_ratings}</div>

          </div>
          <SortableTable
              dataSelector={ratingsSelector}
              headers={columnHeaders}
              renderDataCard={renderRatingCard}
              renderDataRow={renderRatingRow}
              queryData={queryRatings}
              queryState={queryState}
          />
      </>
  )
}

const StyledContent = styled(Popover.Content)`
  padding: 8px;
`;
