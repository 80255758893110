import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IErrorState } from "../app/interfaces/IApiResponses";
import { RootState } from "../app/store";
import { getCompanyEmployees, inviteUser } from "../app/apis/subRatingsAPI";
import { AxiosError } from "axios";

export interface ICompanyEmployee {
    id: number;
    firstName: string;
    lastName: string;
    // recentInviteStatus is not stored serverside. This is populated only from the current session's activity.
    recentInviteStatus?: 'sent' | 'pending' | 'error';
}

export interface CompanyEmployeesState {
    status: 'idle' | 'loading' | 'failed';
    data: Array<ICompanyEmployee>;
    error: undefined | IErrorState;
}

const initialState: CompanyEmployeesState = {
    status: 'idle',
    data: [],
    error: undefined
};

export const getCompanyEmployeesThunk = createAsyncThunk(
    'companyEmployees/getCompanyEmployees',
    async (companyId: number, { rejectWithValue }) => {
        try {
            return await getCompanyEmployees(companyId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const invite = createAsyncThunk(
    'companyEmployees/invite',
    async (
        { companyId, userId }: { companyId: number, userId: number},
        { rejectWithValue }
    ) => {
        try {
            return await inviteUser(companyId, userId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const companyEmployeesSlice = createSlice({
    name: 'companyEmployees',
    initialState,
    reducers: {
        clearCompanyEmployeesError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyEmployeesThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCompanyEmployeesThunk.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload as Array<ICompanyEmployee>;
            })
            .addCase(getCompanyEmployeesThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(invite.pending, (state, action) => {
                const invitedEmployeePosition = state.data.findIndex((employee) => employee.id === action.meta.arg.userId);
                if (invitedEmployeePosition !== -1) {
                    state.data[invitedEmployeePosition].recentInviteStatus = 'pending';
                }
            })
            .addCase(invite.fulfilled, (state, action) => {
                const invitedEmployeePosition = state.data.findIndex((employee) => employee.id === action.meta.arg.userId);
                if (invitedEmployeePosition !== -1) {
                    state.data[invitedEmployeePosition].recentInviteStatus = 'sent';
                }
            })
            .addCase(invite.rejected, (state, action) => {
                const invitedEmployeePosition = state.data.findIndex((employee) => employee.id === action.meta.arg.userId);
                if (invitedEmployeePosition !== -1) {
                    state.data[invitedEmployeePosition].recentInviteStatus = 'error';
                }
            })
    }
});

export const { clearCompanyEmployeesError } = companyEmployeesSlice.actions;

export const selectCompanyEmployees = (state: RootState) => state.companyEmployees.data;

export default companyEmployeesSlice.reducer;
