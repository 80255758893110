import { Button, Flex, Form } from "@procore/core-react";
import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory";
import { CalculatedRating } from "./CalculatedRating";
import { calculateAverage, collectCustomRatings, isSubmissionValid } from "./utils";
import { IRatingSubmission } from "../../app/interfaces/IRating";
import { ProcoreVendorRatingStars, StarSize } from "../stars/ProcoreVendorRatingStars";
import { number, object } from "yup";
import { submitVendorRatingThunk } from "../../slices/vendorsSlice";
import { useAppDispatch } from "../../app/hooks";
import { IProcoreRatingFormProps, IProcoreRatingFormSelection, IProcoreRatingFormValues } from "../../app/interfaces/IProcoreRatingForm";
import styled from "styled-components";
import { useMobileView } from "../../hooks/use-mobile-view";
import { CommitmentSelect } from "./CommitmentSelect";

interface IProcoreCustomCategoryRatingFormProps extends IProcoreRatingFormProps {
    customRatingCategories: ICustomRatingCategory[];
}

export const ProcoreCustomCategoryRatingForm = (
    { 
      commitmentOptions,
      customRatingCategories,
      maybeSelectedCommitment,
      maybeSelectedProject,
      companyId,
      projectOptions,
      vendorId,
      vendorRatingStatus
    }: IProcoreCustomCategoryRatingFormProps
) => {
  const isMobile = useMobileView();
  const formView = vendorRatingStatus === 'idle' ? 'create' : 'read';
  const dispatch = useAppDispatch();
  const yupValidation = customRatingCategories.reduce((acc, category) => {
    if (category.required) {
      acc[category.id] = number().required(`${category.name} is required`);
    }
    return acc;
  }, {} as {[key: number]: any});

  const onSubmit = (formValues: IProcoreRatingFormValues) => {
    const customRatings = collectCustomRatings(customRatingCategories, formValues);
    const overallRating = calculateAverage(customRatings);
    const valid = isSubmissionValid(customRatingCategories, formValues, overallRating);
    const comment = formValues.comment ? formValues.comment as string : null;
    if (!valid) {
      return;
    }

    // getProjectId from formValues.project if present
    const projectId = formValues.project ? (formValues.project as IProcoreRatingFormSelection).id : undefined;
    const commitmentId = formValues.commitment ? (formValues.commitment as IProcoreRatingFormSelection).id : undefined;

    const rating: IRatingSubmission = {
        commitmentId: commitmentId,
        rating: overallRating,
        customRatings: customRatings,
        comment: comment,
        projectId: projectId,
    };

    dispatch(submitVendorRatingThunk({companyId: companyId!, vendorId: vendorId, rating: rating}));
  };

    return (
      <Form
        enableReinitialize={true}
        initialValues={{ commitment: maybeSelectedCommitment, project: maybeSelectedProject }}
        onSubmit={onSubmit}
        view={formView}
        validationSchema={object().shape(yupValidation)}
      >
        <Form.Form>
            <CalculatedRating customRatingCategories={customRatingCategories} />

            {
              projectOptions?.length > 0 &&
                <Form.Row>
                  <Form.Select
                    colWidth={isMobile ? 12 : 6}
                    label='Project'
                    name='project'
                    options={projectOptions}
                    view={ maybeSelectedProject ? 'read' : 'create'}
                  />
                </Form.Row>
            }

            <CommitmentSelect
              companyId={companyId}
              maybeSelectedCommitment={maybeSelectedCommitment}
              options={commitmentOptions}
            />

            <Flex direction="row" wrap='wrap'>
            {
              customRatingCategories?.map((category) => {
                return (
                  <StyledRatingContainer key={category.id}>
                    <Form.Field
                      as={ProcoreVendorRatingStars}
                      incrementByWholes={true}
                      label={category.name}
                      name={category.id.toString()}
                      required={category.required}
                      size={StarSize.Small}
                      type='button'
                    />
                  </StyledRatingContainer>
                );
              })
            }
            </Flex>


            <Form.Row>
              <Form.TextArea name='comment' label='Comment' placeholder='Leave a comment...' colWidth={12}/>
            </Form.Row>
            {
              vendorRatingStatus === 'idle' &&
              <Button type='submit' block>Submit</Button>
            }
        </Form.Form>
      </Form>
    )
};

const StyledRatingContainer = styled.div`
  min-width: 250px;
`;
