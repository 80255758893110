import { 
    getBaseUrl,
    getEnvVariable,
    genRandom,
    navigateWindow,
    setLocalStorageItem,
    getLocalStorageItem,
    getCurrentPath,
    setSessionStorageItem,
    isInIframe,
    getParams
} from "./System";
import { initialize } from '@procore/procore-iframe-helpers';

export const OAUTH_STATE_KEY = 'SUB_RATINGS_OAUTH_STATE';
export const POST_LOGIN_REDIRECT_URL = 'POST_LOGIN_REDIRECT_URL';

/**
 * Based on params passed from Procore, decide what page we should go to.
*/
export const chooseEmbeddedDestination = (): string | undefined => {
    const companyIdParam = getParams().get('procoreCompanyId');
    const projectIdParam = getParams().get('procoreProjectId');

    if (projectIdParam && companyIdParam) {
        return `/companies/${companyIdParam}/projects/${projectIdParam}`;
    } else if (companyIdParam) {
        return`/companies/${companyIdParam}`;
    }
};

export const login = (): void => {
    if (isInIframe()) {
        loginWithEmbeddedProcore();
    } else {
        nativeLoginWithProcore();
    }
};

export const nativeLoginWithProcore = () => {
    const baseUrl = getBaseUrl();
    const redirectUrl = `${baseUrl}/confirmation`;

    const oauthBaseUrl = getEnvVariable('REACT_APP_PROCORE_OAUTH_BASE_URL');
    const clientId = getEnvVariable('REACT_APP_PROCORE_CLIENT_ID');
    
    /**
     * Decide where we should go after login.
     * Use params from Procore if present, otherwise use current path.
     */

    const embeddedDestination = chooseEmbeddedDestination();
    const postLoginDestination = embeddedDestination || getCurrentPath();
    if (postLoginDestination !== '/') {
        setSessionStorageItem(POST_LOGIN_REDIRECT_URL, postLoginDestination);
    }

    // Use a random string here to guard against clickjacking
    const oauthState = genRandom();
    setLocalStorageItem(OAUTH_STATE_KEY, oauthState);
    
    const oauthUrl = `${oauthBaseUrl}/oauth/authorize?` +
        `client_id=${clientId}&` +
        `response_type=code&` + 
        `redirect_uri=${redirectUrl}&` +
        `state=${oauthState}`;

    navigateWindow(oauthUrl);
}

export const loginWithEmbeddedProcore = (): void => {
    
    const baseUrl = getBaseUrl();
    const redirectUrl = `${baseUrl}/embed/confirmation`;

    const oauthBaseUrl = getEnvVariable('REACT_APP_PROCORE_OAUTH_BASE_URL');
    const clientId = getEnvVariable('REACT_APP_PROCORE_CLIENT_ID');

    const isSidePanel = getCurrentPath().includes('side-panel');
    
    /**
     * Decide where we should go after login for full-screen experience. 
    */
    if (!isSidePanel) {
        const embeddedDestination = chooseEmbeddedDestination();
        const postLoginRedirect = embeddedDestination || getCurrentPath();
        if (postLoginRedirect) {
            setSessionStorageItem(POST_LOGIN_REDIRECT_URL, postLoginRedirect);
        }
    }

    // Use a random string here to guard against clickjacking
    const oauthState = genRandom();
    setLocalStorageItem(OAUTH_STATE_KEY, oauthState);
    
    const oauthUrl = `${oauthBaseUrl}/oauth/authorize?` +
        `client_id=${clientId}&` +
        `response_type=code&` + 
        `redirect_uri=${redirectUrl}&` +
        `state=${oauthState}`;

    const postProcoreConfirmRedirect = isSidePanel ? 
        `${baseUrl}/side-panel/landing` :
        `${baseUrl}/confirmation`;

    const embedContext = initialize();
    embedContext.authentication.authenticate({
        url: oauthUrl,
        onSuccess: function(payload) {
            window.location.href = postProcoreConfirmRedirect + payload?.search;
        },
        onFailure: function(error) {
            console.error('Something has gone wrong with authentication to Procore!', error);
        }
    });
};

export const isOauthStateValid = (urlParamState: string | null):boolean => {
    const localStorageState = getLocalStorageItem(OAUTH_STATE_KEY);
    return localStorageState === urlParamState;
};
