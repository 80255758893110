import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory";
import { IProcoreRatingFormValues } from "../../app/interfaces/IProcoreRatingForm";

export const calculateAverage = (customRatings: {id: number, rating: number}[]) => {
  if (customRatings.length === 0) {
    return 0;
  }
  
  const total = customRatings.reduce((acc, rating) => {
    return acc + rating.rating;
  }, 0);

  return total / customRatings.length;
};

export const isSubmissionValid = (
  customRatingCategories: ICustomRatingCategory[] = [],
  formValues: IProcoreRatingFormValues,
  overallRating: number,
): boolean => {
  const requiredCategories = customRatingCategories.filter(category => category.required);
  if (requiredCategories.find(category => !formValues[category.id])) {
    return false;
  }

  if (overallRating < .5) {
    return false;
  }

  return true;
}

export const collectCustomRatings = (
  categories: ICustomRatingCategory[],
  formValues: IProcoreRatingFormValues
): Array<{ id: number, rating: number}> => {
  if (!categories) {
    return [];
  }

  const categoriesWithRatings = categories.filter(category => formValues[category.id] && formValues[category.id] !== 0);

  const categoryValues = categoriesWithRatings.map(category => {
      return {
        id: category.id,
        rating: formValues[category.id] as number,
      };
  });

  return categoryValues;
};
