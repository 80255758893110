import { useNavigate } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import styled from "styled-components";
import HeaderNavItems from "./HeaderNavItems";

const DesktopHeaderNav = () => {
    const navigate = useNavigate();

    return (
        <>
        <StyledMenu secondary fixed="top">
            <Container>
                <Menu.Item
                    name="Sub Ratings"
                    onClick={() => navigate('/')}
                    as="a"
                />
             <HeaderNavItems />
            </Container>
        </StyledMenu>
    </>
    );
};

const StyledMenu = styled(Menu)`
    &&& {
        background-color: ${props => props.theme.colors.darkBackground};
        position: sticky;
        height: 40px;
        &&&  .item {
            color: ${props => props.theme.colors.lightText};
            align-self: flex-start;
            :first-child {
                margin-left: 0;
                padding-left: 0;
            }
        }
        &&& .active .item {
            color: ${props => props.theme.colors.flare};
            border-bottom-width: 10px;
        }
        &&& .item:hover {
            color: ${props => props.theme.colors.flare};
        }
    }
`;

export default DesktopHeaderNav;