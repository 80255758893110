import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { activateTrial, getCheckoutSession, getCompanyServiceAccountStatus, updateCompanySubscription } from "../app/apis/subRatingsAPI";
import { ICreateCustomCategoryResponse, IErrorState, IServiceAccountResponse, IUserResponse } from "../app/interfaces/IApiResponses";
import { ICompany } from "../app/interfaces/ICompany";
import { RootState } from "../app/store";
import { navigateWindow } from "../utils/System";
import { createCustomCategoryThunk } from "./customCategoriesSlice";
import { loginThunk } from "./loginSlice";
import { getUserThunk } from "./userSlice";

export interface CompaniesState {
    status: 'idle' | 'loading' | 'failed';
    checkCompanyServiceAccountStatus: 'idle' | 'loading' | 'failed';
    data: Array<ICompany>;
    error: undefined | IErrorState;
}

const initialState: CompaniesState = {
    status: 'idle',
    checkCompanyServiceAccountStatus: 'idle',
    data: [],
    error: undefined
};

export const activateTrialThunk = createAsyncThunk(
    'companies/activateTrial',
    async (id: number, {rejectWithValue }) => {
        try {
            return await activateTrial(id);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const cancelCompanySubscriptionThunk = createAsyncThunk(
    'companies/cancelCompanySubscription',
    async (companyId: number, { rejectWithValue }) => {
        try {
            return await updateCompanySubscription(companyId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const checkoutThunk = createAsyncThunk(
    'user/signUp',
    async (companyId: number, { rejectWithValue }) => {
        try {
            const response = await getCheckoutSession(companyId);
            navigateWindow(response.url);
            return;        
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const checkServiceAccountStatusThunk = createAsyncThunk(
    'companies/checkServiceAccountStatus',
    async (
        companyId: number,
        { rejectWithValue }
    ) => {
        try {
            const response = await getCompanyServiceAccountStatus(companyId);
            return response;
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
)

export const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        clearCompaniesError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.fulfilled, (state, action) => {
                const payload = action.payload as IUserResponse;
                state.data = payload.companies;
            })
            .addCase(getUserThunk.fulfilled, (state, action) => {
                const payload = action.payload as IUserResponse;
                state.data = payload.companies;
            })
            .addCase(activateTrialThunk.pending, (state, action) => {
                state.status = 'loading';
                state.data = [];
            })
            .addCase(activateTrialThunk.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload as ICompany[];
            })
            .addCase(activateTrialThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(checkoutThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(checkoutThunk.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(checkoutThunk.rejected, (state, action) => {
                state.status = 'failed';
            })
            .addCase(cancelCompanySubscriptionThunk.pending, (state, action) => {
                state.status = 'loading';
                state.data = [];
            })
            .addCase(cancelCompanySubscriptionThunk.fulfilled, (state, action) => {
                state.status = 'idle';
                state.data = action.payload as ICompany[];
            })
            .addCase(cancelCompanySubscriptionThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(checkServiceAccountStatusThunk.pending, state => {
                state.checkCompanyServiceAccountStatus = 'loading';
            })
            .addCase(checkServiceAccountStatusThunk.fulfilled, (state, action) => {
                const payload = action.payload as IServiceAccountResponse;
                const i = state.data.findIndex(co => co.id === payload.companyId);

                // update the service account prop of the company we just checked on.
                if (i >= 0) {
                    if (state.data[i].hasServiceAccount !== payload.hasServiceAccount) {
                        state.data[i].hasServiceAccount = payload.hasServiceAccount;
                    }
                }

                state.checkCompanyServiceAccountStatus = 'idle';
            })
            .addCase(checkServiceAccountStatusThunk.rejected, (state, action) => {
                state.checkCompanyServiceAccountStatus = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(createCustomCategoryThunk.fulfilled, (state, action) => {
                const payload = action.payload as ICreateCustomCategoryResponse;
                const i = state.data.findIndex(co => co.id === payload.companyId);

                // update the hasCustomCategories prop of the company because they just created a custom category.
                if (i >= 0) {
                    if (state.data[i].hasCustomCategories === false) {
                        state.data[i].hasCustomCategories = true;
                    }
                }
            })
    }
});

export const { clearCompaniesError } = companiesSlice.actions;

export const selectCompaniesState = (state: RootState) => state.companies;
export const selectCompanies = (state: RootState) => state.companies.data;
export const selectCompaniesStatus = (state: RootState) => state.companies.status;
export const selectCheckCompanyServiceAccountStatus = (state: RootState) => state.companies.checkCompanyServiceAccountStatus;

export default companiesSlice.reducer;
