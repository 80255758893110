import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { requestRecordsByEmail } from "../app/apis/subRatingsAPI";
import { IErrorState } from "../app/interfaces/IApiResponses";
import { RootState } from "../app/store";

export interface IEmailRequestState {
    status: 'idle' | 'loading' | 'failed';
    error: undefined | IErrorState;
}

const initialState: IEmailRequestState = {
    status: 'idle',
    error: undefined
};

export const requestRecordsByEmailThunk = createAsyncThunk(
    'email/requestRecordsByEmail',
    async (
        currentUrlWithoutDomain: string,
        { rejectWithValue }
    ) => {
        try {
            return await requestRecordsByEmail(currentUrlWithoutDomain);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const emailRequestSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        clearEmailRequestError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(requestRecordsByEmailThunk.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(requestRecordsByEmailThunk.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(requestRecordsByEmailThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
    } 
});

export const { clearEmailRequestError } = emailRequestSlice.actions;
export const selectEmailRequestStatus = (state: RootState) => state.emailRequest.status;
export default emailRequestSlice.reducer;
