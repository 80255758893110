import { useNavigate } from "react-router-dom";
import { Button, Menu } from "semantic-ui-react"
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectCompanies } from "../../slices/companiesSlice";
import { logoutThunk } from "../../slices/loginSlice"
import { login } from "../../utils/User"
import { selectUser } from "../../slices/userSlice";

// closeMenu will handle toggling dropdown in mobile view.
interface IHeaderNavItemsProps {
    closeMenu?: () => void;
}

const HeaderNavItems = ( props: IHeaderNavItemsProps ) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const companies = useAppSelector(selectCompanies);
    const hasSingleCompany = companies ? companies.length === 1: 0;

    const navigateTo = (page: string) => {
        if (props.closeMenu) {
            props.closeMenu();
        }
        navigate(page);
    }
    
    return (
        <>
            <Menu.Item
                name="Help"
                onClick={() => navigateTo('/help')}
                as="a"
            />
            { user && !hasSingleCompany && <Menu.Item
                name='My Companies'
                onClick={() => navigateTo('/companies')}
                as="a"
            /> }
            { user && hasSingleCompany && 
            <>
                <Menu.Item
                    name='My Company'
                    onClick={() => navigateTo(`/companies/${companies[0].id}`)}
                    as="a"
                />
                <Menu.Item
                    name='My Vendors'
                    onClick={() => navigateTo(`/companies/${companies[0].id}/vendors`)}
                    as="a"
                />
                <Menu.Item
                    name='My Projects'
                    onClick={() => navigateTo(`/companies/${companies[0].id}/projects`)}
                    as="a"
                />
            </>
            }
            <Menu.Item position="right">
                {
                    user ? 
                        <StyledButton onClick={() => dispatch(logoutThunk())}>
                            Logout {user?.username}
                        </StyledButton> :
                        <StyledButton onClick={login} >
                            Login / Sign Up
                        </StyledButton>
                }
            </Menu.Item>
        </>
    );

}

const StyledButton = styled(Button)`
    &&&& {
        background: ${props => props.theme.colors.darkBackground};
        color: ${props => props.theme.colors.lightText};
        :hover {
            color: ${props => props.theme.colors.flare};
        }
        padding: 0;
        text-align: left;
    }
`;

export default HeaderNavItems;