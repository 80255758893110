import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { IUser } from "../app/interfaces/IUser";
import { getUserThunk, selectUser } from "../slices/userSlice";
import LogRocket from 'logrocket';

export const useUserAdmin = (): IUser | null => {
    const dispatch = useAppDispatch();
    const userData = useAppSelector(selectUser);

    useEffect(() => {
        if (!userData) {
            dispatch(getUserThunk())
        } else if (process.env.NODE_ENV === 'production') {
            LogRocket.identify(userData.id, {
                name: userData.username,
                email: userData.username,
              });
        }
    }, [userData])

    return userData;
};
