import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Form, Icon, Message, Modal, ModalContent, ModalHeader, Popup, Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { IQueryState, ISortableHeader } from '../../app/interfaces/ISortableTable';
import { IUser } from '../../app/interfaces/IUser';
import { SubmissionState } from '../../app/interfaces/SubmissionState';
import { useUserAdmin } from '../../hooks/use-user-admin';
import { requestRecordsByEmailThunk, selectEmailRequestStatus } from '../../slices/emailRequestSlice';
import { getEnvVariable } from '../../utils/System';
import { StyledDropdown } from '../CommonStyledComponents';
import { Button as ProcoreButton } from '@procore/core-react';

interface IQuerySideBarProps {
    sortOptions: ISortableHeader[];
    queryState?: IQueryState;
    exportable?: boolean; 
}

const QueryBar = ({sortOptions, queryState, exportable}: IQuerySideBarProps) => {
    const apiBaseUrl = getEnvVariable('REACT_APP_SUB_RATING_API_BASE_URL');
    const dispatch = useAppDispatch();
    const [ , setSearchParams] = useSearchParams();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortTerm, setSortTerm] = useState<string>('');
    const [reverse, setReverse] = useState<boolean>(false);
    const [queryStatement, setQueryStatement] = useState<string>('');
    const emailRequestedState = useAppSelector(selectEmailRequestStatus);
    const [emailRequestedModalState, setEmailRequestedModalState] = useState<SubmissionState>(SubmissionState.Idle);
    const user: IUser | null  = useUserAdmin();

    /**
     * Api url pattern is consistent with ui url pattern.
     * So we can use our current url to determine how to get an export!
     * @returns url to download csv for current query
     */
    const createDownloadUrl = () => {

        const exportQueryParam = window.location.search.length > 0 ?
            '&export=true' :
            '?export=true';

        const url = window.location.pathname + window.location.search + exportQueryParam;
        
        return url;
    }

    // If 5000 or more records (50 records per page), file is not downloadable. Offer emailed file instead.
    const shouldOfferEmailInstead: boolean = !!user && !!exportable && !!queryState?.totalPages && queryState.totalPages > 99;
    
    const exportUrlFragment: string | null = exportable ? createDownloadUrl() : null;

    const buildQueryStatement = () => {
        if (!queryState?.search && !queryState?.sort ) {
            return '';
        }

        const searchStatement = queryState?.search ? `Searching for "${queryState.search}"` : '';
        
        // Reverse engineer which sort value we were using by searching our list of options.
        const sortText = queryState?.sort ? sortOptions.find(opt => opt.value === queryState.sort)?.text : null;
        const sortStatement = sortText ? `Sorting ${queryState?.reverse ? 'descending' : 'ascending'} by "${sortText}"` : '';
        
        const joiner = searchStatement.length && sortStatement.length ? ' | ' : '';

        return searchStatement + joiner + sortStatement;
    };

    const submitSearch = () => {
        setSearchParams({});
        let queryParams: any = { };
        
        if (searchTerm.length) {
            queryParams.search = searchTerm;
        }

        if (sortTerm.length) {
            queryParams.sort = sortTerm;
            queryParams.reverse = reverse;
        }

        setSearchParams(queryParams);
    };

    const clearSearchState = () => {
        setSearchTerm('');
        setSortTerm('');
        setReverse(false);
    };

    const clearSearch = () => {
        clearSearchState();
        setSearchParams({});
    };

    const handleSortTermChange = (newTerm: any) => {
        if (typeof newTerm === 'string') {
            setSortTerm(newTerm);
        }
    }

    useEffect(() => {
        const updatedQueryStatement = buildQueryStatement();
        setQueryStatement(updatedQueryStatement);
    }, [queryState])

    // manages whether or not our email request modal should be open
    useEffect(() => {
        // We've just gone from pending to idle, which means success
        if (emailRequestedState === 'idle' && emailRequestedModalState === SubmissionState.Pending) {
            setEmailRequestedModalState(SubmissionState.Success);
            setTimeout(() => setEmailRequestedModalState(SubmissionState.Idle), 4000);
        }  else if (emailRequestedState === 'loading') {
            setEmailRequestedModalState(SubmissionState.Pending)
        } else {
            setEmailRequestedModalState(SubmissionState.Idle)
        }

        return () => setEmailRequestedModalState(SubmissionState.Idle);
    },[emailRequestedState])

    return (
        <StyledQueryBar id='Query-Bar'>
            <StyledModal
                closeIcon
                onClose={() => setEmailRequestedModalState(SubmissionState.Idle)}
                open={emailRequestedModalState === SubmissionState.Success}
                size='mini'
            >
                <ModalHeader>Success</ModalHeader>
                <ModalContent>Your query results will be emailed to {user?.username}.</ModalContent>
            </StyledModal>

            <Form onSubmit={submitSearch} >
                <Form.Group >
                    <StyledFormField width={sortTerm.length ? 5 : 6}>
                        <label>Search Term</label>
                        <Form.Input value={searchTerm} onChange={(e, x) => setSearchTerm(x.value)}/>
                    </StyledFormField>

                    <StyledFormField width={sortTerm.length ? 5 : 6}>
                        <label>Sort</label>
                        <StyledDropdown
                            clearable
                            fluid
                            options={sortOptions}
                            onChange={(e: any, x: any) => handleSortTermChange(x.value)}
                            placeholder='Select a column'
                            value={sortTerm}
                        />
                    </StyledFormField>

                    { sortTerm.length > 0 && 
                        <StyledFormField width='2'>
                            <label>
                            Sort Order
                            </label>
                            <Form.Field>
                                <Radio
                                label='Ascending'
                                name='radioGroup'
                                value='Asc'
                                checked={!reverse}
                                onChange={() => setReverse(false)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                label='Descending'
                                name='radioGroup'
                                value='Dsc'
                                checked={reverse}
                                onChange={() => setReverse(true)}
                                />
                            </Form.Field>
                        
                        </StyledFormField>          
                    }

                    <StyledFormField width={sortTerm.length ? 4 : 6} attached='bottom'>
                        <StyledButtonGroup>
                            <StyledSubmitButton variant='primary' type='submit' >Submit</StyledSubmitButton>
                            <ProcoreButton variant='secondary' onClick={clearSearch}>Clear</ProcoreButton>
                            { 
                                exportable && !shouldOfferEmailInstead && exportUrlFragment &&
                                <Popup 
                                    content='Download current query results to an .xlsx file.'
                                    trigger={
                                        <Button floated='right' icon as='a' download='subratings.xlsx' href={`${apiBaseUrl}${exportUrlFragment}`}>
                                            <Icon name='download' />
                                        </Button>
                                    }
                                />
                            }
                            { 
                                exportable && shouldOfferEmailInstead && exportUrlFragment &&
                                <Popup 
                                    content={`This file is too large to download but we can email the current query results as an .xlsx file to ${user?.username}.`}
                                    trigger={
                                        <Button floated='right' icon onClick={() => dispatch(requestRecordsByEmailThunk(exportUrlFragment!))}>
                                            <Icon name='envelope' />
                                        </Button>
                                    }
                                />
                            }
                        </StyledButtonGroup>
                    </StyledFormField>
                </Form.Group>

                { queryStatement.length > 0 &&  <Message content={queryStatement}></Message> }
                
            </Form>
        </StyledQueryBar>
    )
};

const StyledQueryBar = styled('div')`
    margin-bottom: 40px;
`;

const StyledFormField = styled(Form.Field)`
    &&& {
        margin-bottom: 25px;
    }
`;

const StyledButtonGroup = styled('div')`
    && {
        margin-top: 25px;
    }
`;

const StyledModal = styled(Modal)`
    && {
        & .header {
            background: ${props => props.theme.colors.adminBlue}
        }
    }
`;

const StyledSubmitButton = styled(ProcoreButton)`
    margin-right: 8px;
`;

export default QueryBar;
