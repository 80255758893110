import { useState, useEffect } from "react";
/**
 * determines whether view is desktop using screen width.
 * Updates if screen is resized.
 * @returns true if viewing desktop size screen
 */
export const useDesktopView = (): boolean => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    return width >= 992;
}
