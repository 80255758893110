import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Menu, Icon } from "semantic-ui-react";
import styled from "styled-components";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import HeaderNavItems from "./HeaderNavItems";

const MobileHeaderNav = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const closeDropdown = () => setDropdownOpen(false)
    
    // Close menu if user clicks away.
    const dropdownRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(dropdownRef, closeDropdown);

    return (
        <>
        <StyledMenu secondary fixed="top">
            <Container>
                <Menu.Item
                    name="Sub Ratings"
                    onClick={() => navigate('/')}
                    as="a"
                />
                <Menu.Item 
                    position='right'
                    active={dropdownOpen}
                    onClick={() => setDropdownOpen(true)}
                >  
                    <Icon name='bars' />
                </Menu.Item> 
            </Container>
        </StyledMenu>
        { dropdownOpen &&
            <div ref={dropdownRef}>
                <StyledDropdownMenu fixed="right" secondary vertical>
                    <Container>
                        <StyledCloseButton 
                            position='right'
                            onClick={() => setDropdownOpen(false)}
                            active={dropdownOpen}
                        >
                            <Icon name='close' />
                        </StyledCloseButton>
                        <HeaderNavItems closeMenu={closeDropdown}/>
                    </Container>
                </StyledDropdownMenu>    
            </div>
        }
    </>
    );
};


const StyledMenu = styled(Menu)`
    &&& {
        background-color: ${props => props.theme.colors.darkBackground};
        &&&  .item {
            color: ${props => props.theme.colors.lightText};
            align-self: flex-start;
        }
        &&& .active .item {
            color: ${props => props.theme.colors.flare};
            border-bottom-width: 10px;
        }
        &&& .item:hover {
            color: ${props => props.theme.colors.flare};
        }
    }
`;

const StyledDropdownMenu = styled(Menu)`
    &&& {
        background-color: ${props => props.theme.colors.darkBackground};
        margin-top: 40px;
        &&&  .item {
            color: ${props => props.theme.colors.lightText};
            align-self: flex-start;
        }
        &&& .active .item {
            color: ${props => props.theme.colors.flare};
            border-bottom-width: 10px;
        }
        &&& .item:hover {
            color: ${props => props.theme.colors.flare};
        }
    }
`;

const StyledCloseButton = styled(Menu.Item)`
    &&&& {
        padding-right: 32px;
        width: 100%;
    }
`

export default MobileHeaderNav;