import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { ICustomRatingCategory } from '../../app/interfaces/ICustomRatingCategory';
import { reprioritizeCustomCategoriesThunk } from '../../slices/customCategoriesSlice';
import DraggableCategory from './DraggableCategory';

interface IDraggableCategoriesProps {
    categories: ICustomRatingCategory[];
    categoryToEdit: ICustomRatingCategory | null;
    setCategoryToEdit: (category: ICustomRatingCategory | null) => void;
    editCategory: (category: ICustomRatingCategory) => void;
}

const DraggableCategories = ({ categories }: IDraggableCategoriesProps) => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const companyId = parseInt(params?.companyId!);
    const [draggables, setDraggables] = useState(categories);

    useEffect(() => {
        setDraggables(categories);
    }, [categories])

    const moveCategory = useCallback((dragIndex: number, hoverIndex: number) => {
        let updatedCategories: ICustomRatingCategory[] = [];

        setDraggables((prevDraggables: ICustomRatingCategory[]) => {

            if (dragIndex > hoverIndex) {
                const head = hoverIndex > 0 ? prevDraggables.slice(0, hoverIndex): [];
                const withoutDragItem = prevDraggables.filter((el, i) => i !== dragIndex);
                const tail = withoutDragItem.slice(hoverIndex);
                updatedCategories = [...head, prevDraggables[dragIndex], ...tail];
                return updatedCategories;
            } else {
                const head = prevDraggables.slice(0, hoverIndex + 1).filter((el, i) => i !== dragIndex);
                const tail = prevDraggables.slice(hoverIndex + 1);
                updatedCategories = [...head, prevDraggables[dragIndex], ...tail];
                return updatedCategories;
            }
        });

        const categoriesWithUpdatedPriorities = updatedCategories.map((cat, i) => {
            return { ...cat, priority: i };
        });

        dispatch(reprioritizeCustomCategoriesThunk({companyId, categories: categoriesWithUpdatedPriorities}));
    }, []);

    const renderCategory = useCallback(
        (category: ICustomRatingCategory, index: number) => {
            return (
                <DraggableCategory 
                    category={category}
                    key={category.id}
                    index={index}
                    id={category.id}
                    move={moveCategory}
                />
            );
        },
        []
    );

    return (
        <DndProvider backend={HTML5Backend} >
        {
            draggables.map((cat, index) => renderCategory(cat, index))
        }
        </DndProvider>
    )
};

export default DraggableCategories;
