import React from 'react';
import { Link } from 'react-router-dom';
import { Dimmer, Icon, Loader, Modal } from 'semantic-ui-react';
import { useAppSelector } from '../../app/hooks';
import { ICompany } from '../../app/interfaces/ICompany';
import { selectCompanies } from '../../slices/companiesSlice';

interface IModalProps {
    companyId: number;
    requestStatus: 'idle' | 'loading' | 'failed';
    onClose: () => void;
}

const renderLoadingStatus = (company: ICompany) => {
    return (
        <Dimmer id="loading" active>
            <Loader >Updating {company.name} Service Account Status</Loader >
        </Dimmer>
    );
};

const renderUpdatedStatusModal = (company: ICompany, onClose: () => void) => {

    return (
        <Modal dimmer='blurring' size='tiny' open closeIcon onClose={() => onClose()}>
            <Modal.Header>Service Account Status:&nbsp;
                {
                    company.hasServiceAccount ?
                        <Icon color='green' name='check circle' /> :
                        <Icon color='red' name='x' />
                }
            </Modal.Header>
            <Modal.Content>
                {
                    company.hasServiceAccount ?
                        <p>You have successfully registered a service account. If you just made this change, it may take a few minutes for your data to process.</p> : 
                        <p>We did not find permissions. Please refer to <Link to='/help'>our guide</Link>, check your procore account and try again.</p>
                }
            </Modal.Content>
        </Modal>
    )
}

const CompanyServiceAccountStatusModal = ({companyId, requestStatus, onClose }: IModalProps) => {
    const company = useAppSelector(selectCompanies).find(co => co.id === companyId);
    
    if (!company) {
        return null;
    }
    
    if (requestStatus === 'loading') {
        return renderLoadingStatus(company!);
    } else {
        return renderUpdatedStatusModal(company!, onClose);
    }
}


export default CompanyServiceAccountStatusModal;
