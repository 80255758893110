import { Button, Dimmer, FormInput, Modal, Popup, SemanticCOLORS } from "semantic-ui-react"
import { ICompany } from "../../app/interfaces/ICompany";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ICompanyEmployee, getCompanyEmployeesThunk, invite, selectCompanyEmployees } from "../../slices/companyEmployeesSlice";
import styled from "styled-components";
import { debounce } from 'lodash';

export const InviteUsersModal = ({company, onClose }: { company: ICompany, onClose: () => void }) => {
    const dispatch = useAppDispatch();
    const companyUsers = useAppSelector(selectCompanyEmployees);
    
    const [filter, setFilter] = useState<string | undefined>(undefined);
    const [filteredUsers, setFilteredUsers] = useState<ICompanyEmployee[]>([]);

    const handleEmailUser = (userId: number) => {
        dispatch(invite({ companyId: company.id, userId }));
    };

    const filterUsers = debounce(() => {
        if (filter?.length && companyUsers.length > 0) {
            setFilteredUsers(companyUsers.filter(u => 
                (`${u.firstName} ${u.lastName}`).toLowerCase().includes(filter.toLowerCase())
            ));
        } else if (filter === '' || filter === undefined) {
            setFilteredUsers(companyUsers);
        }
    }, 300);

    useEffect(() => {
        dispatch(getCompanyEmployeesThunk(company.id));
    }, [])

    // filter users based on changes to filter input
    useEffect(() => {
        filterUsers();
    }, [filter, companyUsers])

    return (
        <Modal blurring='true' closeIcon onClose={onClose} open={true} size='tiny'>
            <Modal.Header>Invite users from {company.name}</Modal.Header>
            <Dimmer.Dimmable
                as={Modal.Content}
                dimmed={false}
            >
                <Modal.Content>
                    <p>Invite users from your company's email domain.</p>
                    <StyledFormInput
                        icon='search'
                        value={filter}
                        placeholder='Search by name'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter(e.target.value)}
                    />
                    <StyledUserTable>
                    { 
                        filteredUsers.map(u => {
                            const userStatus = u.recentInviteStatus || 'none';

                            const colorMap = {
                                error: 'red' as SemanticCOLORS,
                                none: undefined,
                                pending: undefined,
                                sent: 'green' as SemanticCOLORS,
                            };

                            const iconMap = {
                                error: 'exclamation',
                                none: 'envelope',
                                pending: 'spinner',
                                sent: 'check',
                            };

                            const popupContentMap = {
                                error: `An error has occured while sending an invitation to ${u.firstName} ${u.lastName}. Please try again later or contact us if the issue persists.`,
                                none: `Invite ${u.firstName} ${u.lastName} to use Sub Ratings.`,
                                pending: `Sending invite to ${u.firstName} ${u.lastName}...`,
                                sent: `An invitation has been sent to ${u.firstName} ${u.lastName} on your behalf.`,
                            };

                            return (
                                <StyledUserRow key={u.id}>
                                    <p>{u.firstName} {u.lastName}</p>
                                    <Popup
                                        content={popupContentMap[userStatus]}
                                        trigger={
                                                    <Button
                                                        circular
                                                        disabled={userStatus === 'pending' || userStatus === 'sent'}
                                                        color={colorMap[userStatus]}
                                                        icon={iconMap[userStatus]}
                                                        loading={userStatus === 'pending'}
                                                        onClick={() => handleEmailUser(u.id)}
                                                        size='mini'
                                                    />
                                        }
                                    />
                                </StyledUserRow>
                            )
                        })
                    }
                    </StyledUserTable>
                </Modal.Content>
            </Dimmer.Dimmable>
        </Modal>
    )
};

const StyledUserRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    p {
        margin-bottom: 0;
    }
`;

const StyledUserTable = styled.div`
    height: 400px;
    overflow-y: scroll;
    div:nth-child(even) {
        background-color: ${props => props.theme.colors.adminBlue};
    }
`;

const StyledFormInput = styled(FormInput)`
    margin-bottom: 15px;
`;
