import { initialize } from '@procore/procore-iframe-helpers';

/**
 * This page will render for a split second in a separate window,
 * after Procore login through the embedded experience.
 * Its only job is to pass the oauth credentials from the url query string
 * in the payload to the OnSuccess function in loginWithEmbeddedProcore 
 * within embedContext.authentication.authenticate.
 * 
 * Once we have that payload, we'll redirect to our standard confirmation page
 * with the payload's query params in the url.
 * Then we can resume standard oauth flow.
 */
export const EmbedConfirmation = () => {
    const embedContext = initialize();
    const search = window.location.search;
    embedContext.authentication.notifySuccess({ search });

    return (
        <>
            <h1>Logging you in through Procore...</h1>
        </>
    );
};
