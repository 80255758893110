import { FormFieldAPI, P, useFormContext } from "@procore/core-react";
import { useEffect, useState } from "react";
import { ProcoreVendorRatingStars, StarSize } from "../stars/ProcoreVendorRatingStars";
import { calculateAverage, collectCustomRatings } from "./utils";
import styled from "styled-components";
import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory";

interface ICalculatedRatingProps {
  customRatingCategories: ICustomRatingCategory[];
}

/**
 * This component is used to display the average rating based on custom categories on the vendor rating form.
 */
export const CalculatedRating = ({ customRatingCategories }: ICalculatedRatingProps) => {
  const context = useFormContext();
  const [average, setAverage] = useState<number>(0);

  useEffect(() => {
    const customRatings = collectCustomRatings(customRatingCategories, context.values);
    const average = calculateAverage(customRatings);
    setAverage(average);
  }, [context.values])

  // We're simulating the data from procore's formik here since we don't actually want this to be a form field.
  const fakeField = {
    input: {
      value: average
    },
    meta: {
      disabled: true
    },
    helpers: { }
  } as FormFieldAPI<number>;

  return (
    <StyledContainer>
      <StyledLabel>Overall Rating</StyledLabel>
      <P>(Averaged from categories below.)</P>
      <ProcoreVendorRatingStars
        displayNumericalValue={true}
        field={fakeField}
        size={StarSize.Medium}
      />
    </StyledContainer>
  );
}

const StyledContainer = styled('div')`
  margin-bottom: 20px;
`;

const StyledLabel = styled('label')`
  font-size: 14px;
  font-weight: 700;
`;
