import React, {FC, useState} from 'react';
import { Check, Help } from '@procore/core-icons';
import { ICompany, SubscriptionStatus } from '../../app/interfaces/ICompany';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { activateTrialThunk, checkServiceAccountStatusThunk, selectCheckCompanyServiceAccountStatus, selectCompanies } from '../../slices/companiesSlice';
import SortableTable from '../tables/SortableTable';
import { ISortableHeader } from '../../app/interfaces/ISortableTable';
import CompanyServiceAccountStatusModal from './CompanyServiceAccountModal';
import { Button, H2, Table, Tile, Title } from '@procore/core-react';
import { ProcoreGenericCell, ProcoreLinkCell, ProcoreTextCell } from '../tables/ProcoreTableCells';
import styled from 'styled-components';
import { StyledProcoreTile, StyledTileActions } from '../CommonStyledComponents';
import { Link } from 'react-router-dom';

const Companies: FC = () => {
    const dispatch = useAppDispatch();
    const checkCompanyServiceAccountRequestStatus = useAppSelector(selectCheckCompanyServiceAccountStatus);
    const [companyIdForServiceAcctCheck, setCompanyIdForServiceAcctCheck] = useState<number | null>(null)

    const onCheckServiceAccountStatus = (companyId: number) => {
        setCompanyIdForServiceAcctCheck(companyId);
        dispatch(checkServiceAccountStatusThunk(companyId));
    };

    const columnHeaders: ISortableHeader[] = [
        { value: 'name', key: 'name', text: 'Name', sortable: true },
        { value: 'projects', key: 'projects', text: 'Projects', sortable: false },
        { value: 'vendors', key: 'vendors', text: 'Vendors', sortable: false },
        { value: 'admin', key: 'admin', text: 'Admin', sortable: false },
        { value: 'has_service_account', key: 'has_service_account', text: 'Procore Service Account', sortable: false }
    ];

    //TODO: handle the conditionals used in the renderCompanyRow function
    const renderCard = (dataItem: any) => {
        const company = dataItem as ICompany;
        return (
            <StyledProcoreTile orientation="portrait">
                <Tile.Content>
                    <Title>
                        <Title.Text>
                            <H2>
                                <Link to={`/companies/${company.id}`} >
                                    {company.name}
                                </Link>
                            </H2>
                        </Title.Text>
                        <Title.Subtext>
                            Subscription Status: {company.subscriptionStatus}
                        </Title.Subtext>
                    </Title>

                {
                    !company.hasServiceAccount ?
                        <>
                            <div>
                                Set up service account to view projects and vendors. &nbsp;
                                <a href={`https://app.procore.com/${company.id}/company/admin/app_installations`} target="_blank" rel="noreferrer">Install on Procore</a>
                                &nbsp;
                                <a href="/help"><Help size='sm'/></a>
                            </div>
                        </>
                         :
                        <>
                            <div>
                                <Link to={`/companies/${company.id}/projects`} >
                                    Projects
                                </Link>
                            </div>
                            <div>
                                <Link to={`/companies/${company.id}/vendors`} >
                                    Vendors
                                </Link>
                            </div>
                        </>
                }

                </Tile.Content>

                {
                    (!company.hasServiceAccount || company.subscriptionStatus === SubscriptionStatus.None) &&
                    <StyledTileActions>
                        {
                            !company.hasServiceAccount &&
                                <Button size='sm' onClick={() => onCheckServiceAccountStatus(company.id)}>Re-check Service Account</Button>
                        }
                        {
                            company.subscriptionStatus === SubscriptionStatus.None &&
                            <Button size='sm' onClick={() => dispatch(activateTrialThunk(company.id))}>Begin Trial</Button>
                        }
                    </StyledTileActions>
                }

            </StyledProcoreTile>
        );
    };

    const renderCompanyRow = (dataItem: any) => {
        const company = dataItem as ICompany;

        return (
            <React.Fragment key={company.id} >
            <Table.BodyRow key={`${company.id}-${company.subscriptionStatus}`} >
                <ProcoreLinkCell to={`/companies/${company.id}`} cta={company.name} />
                {
                    company.hasServiceAccount ?
                        <ProcoreLinkCell to={`/companies/${company.id}/projects`} cta="Projects" /> :
                        <ProcoreTextCell text='Set up Service Account to view' />
                }
                {
                    company.hasServiceAccount ?
                        <ProcoreLinkCell to={`/companies/${company.id}/vendors`} cta="Vendors" /> :
                        <ProcoreTextCell text='Set up Service Account to view' />
                }
                {
                    company.subscriptionStatus === SubscriptionStatus.None ?
                        <ProcoreGenericCell>
                            <Button size='sm' onClick={() => dispatch(activateTrialThunk(company.id))}>Begin Trial</Button>
                        </ProcoreGenericCell> :
                        <ProcoreLinkCell to={`/companies/${company.id}/admin`} cta="Subscription Settings" />
                }
                {
                    company.hasServiceAccount ?
                        <Table.BodyCell >
                            <Table.IconCell style={{flexDirection: 'row', justifyContent: 'center'}}>
                                <Check />
                            </Table.IconCell>
                        </Table.BodyCell>
                        :
                        <ProcoreGenericCell>
                            <StyledServiceAccountContent>
                                <div>
                                    <a href={`https://app.procore.com/${company.id}/company/admin/app_installations`} target="_blank" rel="noreferrer">Install on Procore</a>
                                    &nbsp;
                                    <a href="/help"><Help size='sm'/></a>
                                </div>
                                <Button size='sm' onClick={() => onCheckServiceAccountStatus(company.id)}>Re-check Status</Button>
                            </StyledServiceAccountContent>
        
                        </ProcoreGenericCell>
                }

            </Table.BodyRow>
            </React.Fragment>
        )
    };

    return (
        <div id="companies">
            <h1>Companies</h1>
            {
                companyIdForServiceAcctCheck &&
                <CompanyServiceAccountStatusModal
                    companyId={companyIdForServiceAcctCheck}
                    onClose={() => setCompanyIdForServiceAcctCheck(null)}
                    requestStatus={checkCompanyServiceAccountRequestStatus}
                />
            }
            <SortableTable
                dataSelector={selectCompanies}
                headers={columnHeaders}
                renderDataCard={renderCard}
                renderDataRow={renderCompanyRow}
            />
        </div>
    );
};

export default Companies;

const StyledServiceAccountContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;