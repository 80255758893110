import { FormFieldAPI } from "@procore/core-react";
import { IRatingCustomCategory } from "../../app/interfaces/IRating";
import { ProcoreVendorRatingStars, StarSize } from "../stars/ProcoreVendorRatingStars";

export const VendorRatingsCategories = ({ categories }: { categories: IRatingCustomCategory[]}) => {

    const toField = (rating: number) => {
      return {
        input: {
          value: rating
        },
        meta: {
            disabled: true
        },
        helpers: { }
      } as FormFieldAPI<number>; 
    };
  
    return (
      <>
        {
          categories?.map((c) => {
              return (
                <div key={c.id}>
                  {c.category.name}
                  <ProcoreVendorRatingStars
                    size={StarSize.Small}
                    field={toField(c.rating)}
                  />
                </div>
              )
          })
        }
      </>
    );
};
