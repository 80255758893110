import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getProjectVendors, getCompanyVendors, submitVendorRating } from "../app/apis/subRatingsAPI";
import { IErrorState, ISearchableResponse } from "../app/interfaces/IApiResponses";
import { IRatingSubmission, IRatingSubmissionsResponse } from "../app/interfaces/IRating";
import { IQueryState, IQueryParams } from "../app/interfaces/ISortableTable";
import { IVendor } from "../app/interfaces/IVendor";
import { RootState } from "../app/store";

export interface VendorsState {
    status: 'idle' | 'loading' | 'failed';
    vendorRatingStatus: 'idle' | 'loading' | 'failed' | 'success';
    queryState: IQueryState | undefined;
    data: Array<IVendor>
    error: undefined | IErrorState;
}

const initialState: VendorsState = {
    status: 'idle',
    vendorRatingStatus: 'idle',
    queryState: undefined,
    data: [],
    error: undefined
};

export const getCompanyVendorsThunk = createAsyncThunk(
    'vendors/getCompanyVendors',
    async (
        {companyId, queryParams }: { companyId: number, queryParams?: IQueryParams },
        { rejectWithValue }
    ) => {
        try {
            return await getCompanyVendors(companyId, queryParams);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const getProjectVendorsThunk = createAsyncThunk(
    'vendors/getProjectVendors',
    async (
            { companyId, projectId, queryParams }: { companyId: number, projectId: number, queryParams?: IQueryParams },
            { rejectWithValue }
        ) => {
            try {
                return await getProjectVendors(companyId, projectId, queryParams);
            } catch (err) {
                const error = err as AxiosError;
                return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
            }
    }
);

export const submitVendorRatingThunk = createAsyncThunk(
    'vendor/submitVendorRating',
    async ({ companyId, vendorId, rating }: { companyId: number, vendorId: number, rating: IRatingSubmission }) => {
        return await submitVendorRating(companyId, vendorId, rating);
    }
);

export const vendorsSlice = createSlice({
    name: 'vendors',
    initialState,
    reducers: {
        clearVendorsError: (state) => {
            state.error = undefined;
        },
        resetVendorRatingStatus: (state) => {
            state.vendorRatingStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyVendorsThunk.pending, (state) => {
                state.status = 'loading';
                state.data = [];
            })
            .addCase(getCompanyVendorsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(getCompanyVendorsThunk.fulfilled, (state, action) => {
                const response = action.payload as ISearchableResponse<Array<IVendor>>;
                state.data = response.data;
                state.queryState = response.queryState;
                state.status = 'idle';
            })
            .addCase(getProjectVendorsThunk.pending, (state) => {
                state.status = 'loading';
                state.data = [];
            })
            .addCase(getProjectVendorsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(getProjectVendorsThunk.fulfilled, (state, action) => {
                const response = action.payload as ISearchableResponse<Array<IVendor>>;
                state.data = response.data;
                state.queryState = response.queryState;
                state.status = 'idle';
            })
            .addCase(submitVendorRatingThunk.pending, (state) => {
                state.status = 'loading'
                state.vendorRatingStatus = 'loading';
            })
            .addCase(submitVendorRatingThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.vendorRatingStatus = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(submitVendorRatingThunk.fulfilled, (state, action) => {
                const { vendor: updatedVendor } = action.payload as IRatingSubmissionsResponse;
                
                // If we submit a new rating, we must update the average rating for that vendor.
                const updatedVendorIndex = state.data.findIndex(v => v.id === updatedVendor.id);
                if (updatedVendorIndex > -1) {
                    const head = state.data.slice(0, updatedVendorIndex);
                    const tail = state.data.slice(updatedVendorIndex + 1, state.data.length + 1);
                    state.data = [...head, updatedVendor, ...tail];
                }
                state.vendorRatingStatus = 'success';
                state.status = 'idle';
            })
    }
});

export const { clearVendorsError, resetVendorRatingStatus } = vendorsSlice.actions;

export const selectVendors = (state: RootState) => state.vendors.data;
export const selectVendorStatus = (state: RootState) => state.vendors.status;
export const selectVendorRatingStatus = (state: RootState) => state.vendors.vendorRatingStatus;
export const selectVendorsState = (state: RootState) => state.vendors;
export default vendorsSlice.reducer;