import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectCompanies } from '../../slices/companiesSlice';
import { getSessionStorageItem, removeSessionStorageItem } from '../../utils/System';
import { POST_LOGIN_REDIRECT_URL } from '../../utils/User';


/**
 * This component is used to handle the redirect after a user has logged in.
 * If login succeeded, the user will be redirected to the page they were on.
*/
const Confirmation = () => {
    const navigate = useNavigate();
    const companies = useAppSelector(selectCompanies);
    const postLoginRedirectUrl = getSessionStorageItem(POST_LOGIN_REDIRECT_URL);

    useEffect(() => {
        // We're using companies as an indicator that a user has logged in.
        // Are we going to regret this?
        if (!companies?.length) {
            return;
        }

        if (postLoginRedirectUrl) {
            removeSessionStorageItem(POST_LOGIN_REDIRECT_URL);
            navigate(postLoginRedirectUrl);
        } else if (companies.length === 1) {
            navigate(`/companies/${companies[0].id}`);
        } else {
            navigate('/companies');
        }
    }, [companies])


    return (
    <>
        <h1>Logging in...</h1>
    </>
    );
};

export default Confirmation;
