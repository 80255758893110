import { Table } from "@procore/core-react";
import { Link } from "react-router-dom";

interface IProcoreLinkCellProps {
  to: string;
  cta: string;
}

export const ProcoreLinkCell = ({ to, cta }: IProcoreLinkCellProps) => {
    return (
        <Table.BodyCell>
            <Table.TextCell>
                <Link to={to}>{cta}</Link>
            </Table.TextCell>
        </Table.BodyCell>
    )
};

export const ProcoreTextCell = ({ text }: { text: string }) => {
    return (
        <Table.BodyCell>
            <Table.TextCell>
                {text}
            </Table.TextCell>
        </Table.BodyCell>
    )
};

export const ProcoreGenericCell = ({ children }: { children: React.ReactNode }) => {
    return (
        <Table.BodyCell>
            <Table.TextCell>
                {children}
            </Table.TextCell>
        </Table.BodyCell>
    )
};

export const ProcoreCurrencyCell = ({ amount }: { amount: string }) => {
    return (
        <Table.BodyCell>
            <Table.CurrencyCell>
                {amount}
            </Table.CurrencyCell>
        </Table.BodyCell>
    )
};

export const ProcorePercentCell = ({ amount }: { amount: string }) => {
    return (
        <Table.BodyCell>
            <Table.PercentCell>
                {amount}
            </Table.PercentCell>
        </Table.BodyCell>
    )
};

export const ProcoreEmptyCell = () => {
    return (
        <Table.BodyCell>
        </Table.BodyCell>
    )
};
