import React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory";
import { getCustomCategoriesThunk, createCustomCategoryThunk,  selectCustomCategoriesState, editCustomCategoryThunk } from "../../slices/customCategoriesSlice";
import { StyledRequiredAsterisk, StyledSegment } from "../CommonStyledComponents";
import CustomRatingCategoryForm from "./CustomRatingCategoryForm";
import DraggableCategories from './DraggableCategories';

const CustomRatingCategories = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const companyId = parseInt(params?.companyId!);
    const { data: categories } = useAppSelector(selectCustomCategoriesState);
    const [inCreateMode, setInCreateMode] = useState<boolean>(false);
    const [categoryToEdit, setCategoryToEdit] = useState<null | ICustomRatingCategory>(null);

    const createCategory = (category: ICustomRatingCategory) => {
        dispatch(createCustomCategoryThunk({ companyId, category }));
        setInCreateMode(false)
    };

    const editCategory = (category: ICustomRatingCategory) => {
        dispatch(editCustomCategoryThunk({companyId, category}));
        setCategoryToEdit(null);
    }

    useEffect(() => {
        dispatch(getCustomCategoriesThunk(companyId));
    }, [])

    return (
        <StyledCustomRatingCategoriesContainer id='custom-rating-categories'>
            { inCreateMode ?
                <CustomRatingCategoryForm 
                    cancel={() => setInCreateMode(false)} 
                    save={createCategory}
                />
                
                :

                <StyledSegment compact>
                    <p>Add a Custom Ratings Category</p>
                    <Button floated='right' onClick={() => setInCreateMode(true)} icon>
                        <Icon name='plus' />
                    </Button>
                </StyledSegment>
            }

            {
                categories.length > 0 &&
                <DraggableCategories
                    categories={categories}
                    categoryToEdit={categoryToEdit}
                    setCategoryToEdit={setCategoryToEdit}
                    editCategory={editCategory}
                />
            }
            <p><StyledRequiredAsterisk size='tiny' name='asterisk' /> denotes a required rating category.</p>

        </StyledCustomRatingCategoriesContainer>
        
    );
}

const StyledCustomRatingCategoriesContainer = styled('div')`
    max-width: 550px;
`;


export default CustomRatingCategories;
