import { useEffect, useState } from "react";
import { Button, ButtonGroup, FormButton, FormInput, FormRadio, Popup } from "semantic-ui-react";
import { ICustomRatingCategory } from "../../app/interfaces/ICustomRatingCategory";
import { StyledFormGroup, StyledSegment } from "../CommonStyledComponents";

interface ICustomRatingCategoryFormProps {
    category?: ICustomRatingCategory;
    cancel: () => void;
    save: (category: ICustomRatingCategory) => void;
}

const CustomRatingCategoryForm = ({category, cancel, save}: ICustomRatingCategoryFormProps)  => {
    const [newCategoryName, setNewCategoryName] = useState(category ? category.name : '');
    const [newCategoryRequired, setNewCategoryRequired] = useState<boolean>(category ? category.required : false);
    // if this is a new category, just use 0 for id as a placeholder
    const [newCategoryId, ] = useState<number>(category? category.id : 0);
    const [formError, setFormError] = useState<boolean>(false);

    const onSave = () => {
        // Validate
        if (!newCategoryName.length) {
            setFormError(true);
            return;
        }

        const newCategory = {
            id: newCategoryId,
            name: newCategoryName,
            required: newCategoryRequired
        };

        save(newCategory);
    }

    // Update validation errors if user adds name
    useEffect(() => {
        if (formError && newCategoryName.length) {
            setFormError(false);
        }
    }, [newCategoryName])

    return (
        <StyledSegment id='custom-rating-category-form'>
            <StyledFormGroup>
                {
                    category?.isInUse ?
                            <Popup
                                content='This category is already in use. Its name can no longer be edited.'
                                trigger={
                                    <div> 
                                        <FormInput
                                            disabled
                                            value={newCategoryName}
                                            placeholder='Name'
                                            onChange={(e,x) => setNewCategoryName(x.value)}
                                            error={formError}
                                            icon='lock'
                                        />
                                    </div>
                                }
                            />
                    :
                        <FormInput
                            value={newCategoryName}
                            placeholder='Name'
                            onChange={(e,x) => setNewCategoryName(x.value)}
                            error={formError}
                        />
                }
                <FormRadio
                    label='Required'
                    onClick={() => setNewCategoryRequired(!newCategoryRequired)}
                    checked={newCategoryRequired}
                />
            </StyledFormGroup>

            <ButtonGroup basic>
                <FormButton 
                    positive
                    icon='check'
                    onClick={onSave}
                    disabled={formError}
                    floated='right'
                />
                <Button negative icon='close' onClick={cancel}></Button>
            </ButtonGroup>
        </StyledSegment>
    );
}

export default CustomRatingCategoryForm;

