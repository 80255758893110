import { Page, Tile } from "@procore/core-react";
import { Button, Dropdown, FormGroup, Icon, Segment } from "semantic-ui-react";
import styled from "styled-components";

export const StyledLi = styled('li')`
    margin-top: 10px;
`;

export const StyledProcoreTile = styled(Tile)`
    margin-bottom: 16px;
`;

export const StyledTileActions = styled(Tile.Actions)`
    display: flex;
    margin: 16px;
`;

/*
* Some really finicky styling to make it match the style of a text box.
*/
export const StyledDropdown = styled(Dropdown)`
    && {
        border: 1px solid rgba(34,36,38,.15);
        border-radius: .28571429rem;
        color: black;
        line-height: 1.21428571em;
        padding: 9.5px 14px;
    }
`;

export const StyledFormGroup = styled(FormGroup)`
    align-items: center;
    display: flex;
    width: 50%;
    &&& .field {
        margin-right: 10px;
        min-width: 250px%
    }
    &&& .error {
        border: 1px solid ${props => props.theme.colors.crimsonSky};
    }
`;

export const StyledInlineButton = styled(Button)`
    &&& {
        margin-left: 20px;
    }
`;

export const StyledSegment = styled(Segment)`
    &&&& {
        align-items: center;
        display: flex;
        justify-content: space-between;
        && p {
            margin: 0 15px 0 0;
        }
    }
`;

export const StyledUl = styled('ul')`
    list-style-type: none;
    padding-left: 5px;
`;

export const StyledRequiredAsterisk = styled(Icon)`
    color: ${props => props.theme.colors.crimsonSky};
    padding-top: 3px;
    vertical-align: top;
`;

export const StyledMain = styled(Page.Main)`
    padding-bottom: 24px;
`;
