import { useEffect } from "react";
import { chooseSidePanelDestination } from "../../utils/SidePanel";
import { useNavigate } from "react-router-dom";

export const SidePanelLanding = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const context = event?.data?.context;
      if (context) {
        const destination = chooseSidePanelDestination(context);
        navigate(destination);
      }
  });

  window.parent.postMessage({ type: 'initialize' }, '*');
  }, []);
  
  return <>Welcome to the side panel...</>;
};
