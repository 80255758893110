import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { loginThunk } from "../slices/loginSlice";
import { isOauthStateValid } from "../utils/User";

const hasValidOauthResponse = (code: string | null, state: string | null) => {
    if (!code || !state) {
        return false;
    }

    const validOauthState = isOauthStateValid(state);
    if (!validOauthState) {
        console.error('Oauth state returned from Procore is not valid, which indicates an attempt at clickjacking. Please contact administrator.');
        return false;
    }

    return true;
};

export const useOauthState = (): void => {
    const [searchParams, setSearchParams] = useSearchParams();
    const codeParam = searchParams.get('code');
    const stateParam = searchParams.get('state');
    const oauthCode = codeParam ? codeParam : null;
    const oauthState = stateParam ? stateParam : null;

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (
            hasValidOauthResponse(oauthCode, oauthState)
        ) {
            dispatch(loginThunk(oauthCode as string));
            
            // now that we've used state and code, clean up the url
            setSearchParams({});
        }

    }, [oauthCode, oauthState]);

};
