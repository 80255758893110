import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getQuerySettingsThunk, selectQuerySettingsState, setQuerySettingsThunk } from "../../slices/querySettingsSlice";
import { Switch } from "@procore/core-react";
import { useEffect } from "react";
import { Loader } from "semantic-ui-react";

const QuerySettings = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const companyId = parseInt(params?.companyId!);
  const { data: querySettings } = useAppSelector(selectQuerySettingsState);

  useEffect(() => {
    if (!querySettings) {
      dispatch(getQuerySettingsThunk(companyId));
    }
  }, []);

  if (!querySettings) { 
    return <Loader />;
  }

  return (
    <div>
      <Switch
        checked={!!querySettings?.showInactiveProjects}
        onChange={() => dispatch(setQuerySettingsThunk({ companyId, querySettings: { showInactiveProjects: !querySettings?.showInactiveProjects } }))}
      >Show Inactive Projects</Switch>

      <Switch
        checked={!!querySettings?.showTestProjects}
        onChange={(x) => dispatch(setQuerySettingsThunk({ companyId, querySettings: { showTestProjects: !querySettings?.showTestProjects } }))}
      >Show Test Projects</Switch>
    </div>
  )
};

export default QuerySettings;