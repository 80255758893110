import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getVendorRatings, getVendorDetail, getCommitmentVendor } from "../app/apis/subRatingsAPI";
import { IErrorState, ISearchableResponse } from "../app/interfaces/IApiResponses";
import { IRating, IRatingSubmissionsResponse } from "../app/interfaces/IRating";
import { IQueryState, IQueryParams } from "../app/interfaces/ISortableTable";
import { IVendor } from "../app/interfaces/IVendor";
import { RootState } from "../app/store";
import { submitVendorRatingThunk } from "./vendorsSlice";

export interface VendorDetailState {
    status: 'idle' | 'loading' | 'failed' | 'unauthorized';
    queryState: IQueryState | undefined;
    vendor: IVendor | undefined;
    ratings: IRating[];
    error: undefined | IErrorState;
}

const initialState: VendorDetailState = {
    status: 'idle',
    queryState: undefined,
    vendor: undefined,
    ratings: [],
    error: undefined
};

export const getVendorDetailThunk = createAsyncThunk(
    'vendor/getVendorDetail',
    async (
        {companyId, vendorId}: { companyId: number, vendorId: number },
        { rejectWithValue }
    ) => {
        try {
            return await getVendorDetail(companyId, vendorId)
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const getCommitmentVendorThunk = createAsyncThunk(
    'vendor/getVendorDetailByCommitment',
    async (
        {companyId, projectId, commitmentId}: { companyId: number, projectId: number, commitmentId: number },
        { rejectWithValue }
    ) => {
        try {
            return await getCommitmentVendor(companyId, projectId, commitmentId);
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const getVendorRatingsThunk = createAsyncThunk(
    'vendor/getVendorRatings',
    async (
        {companyId, vendorId, queryParams}: { companyId: number, vendorId: number, queryParams?: IQueryParams },
        { rejectWithValue }
    ) => {
        try {
            return await getVendorRatings(companyId, vendorId, queryParams)
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
)

export const vendorDetailSlice = createSlice({
    name: 'vendorDetail',
    initialState,
    reducers: {
        clearVendorDetailError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVendorDetailThunk.pending, (state) => {
                state.status = 'loading';
                state.vendor = undefined;
            })
            .addCase(getVendorDetailThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(getVendorDetailThunk.fulfilled, (state, action) => {
                state.vendor = action.payload as IVendor;
                state.status = 'idle';
            })
            .addCase(getCommitmentVendorThunk.pending, (state) => {
                state.status = 'loading';
                state.vendor = undefined;
            })
            .addCase(getCommitmentVendorThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
            })
            .addCase(getCommitmentVendorThunk.fulfilled, (state, action) => {
                state.vendor = action.payload as IVendor;
                state.status = 'idle';
            })
            .addCase(getVendorRatingsThunk.pending, (state) => {
                state.status = 'loading';
                state.ratings = [];
            })
            .addCase(getVendorRatingsThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                state.error = errorResponse;
                state.ratings = [];
            })
            .addCase(getVendorRatingsThunk.fulfilled, (state, action) => {
                const response = action.payload as ISearchableResponse<Array<IRating>>;
                state.ratings = response.data;
                state.queryState = response.queryState;
                state.status = 'idle';
            })
            .addCase(submitVendorRatingThunk.fulfilled, (state, action) => {
                const { vendor: updatedVendor, rating } = action.payload as IRatingSubmissionsResponse;
                
                // If we submit a new rating, we must update the average rating and list of ratings for that vendor.
                if (state.vendor?.id === updatedVendor.id) {
                    state.vendor = updatedVendor;
                    state.ratings = [rating, ...state.ratings]
                }
            })
    }
});

export const { clearVendorDetailError } = vendorDetailSlice.actions;

export const ratingsSelector = (state: RootState) => state.vendorDetail.ratings;
export const selectVendorDetailState = (state: RootState) => state.vendorDetail;
export default vendorDetailSlice.reducer;
