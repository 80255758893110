import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "semantic-ui-react";
import { useAppSelector } from "../../app/hooks";
import { IQueryParams, IQueryState, ISortableHeader } from "../../app/interfaces/ISortableTable";
import { RootState } from "../../app/store";
import QueryBar from "./QueryBar";
import { Table } from "@procore/core-react";
import { useDesktopView } from "../../hooks/use-desktop-view";
/**
 * @property data: Array of items to be sorted and displayed
 * @property headers: List of table headers to display in table
 * @property queryState: Tracks which page number and search term were used to get existing data
 * @property renderDataRow: A function from the parent which renders a data row 
 * @property updateData: A function to update data in table with new page numbers and/or search term
 * @property downloadable: Corresponding api supports returning csv file for query
*/
interface ISortableTableProps {
    dataSelector: (state: RootState) => any[];
    headers: ISortableHeader[];
    queryState?: IQueryState;
    renderDataRow: (dataItem: any) => JSX.Element;
    renderDataCard: (dataItem: any) => JSX.Element;
    //TODO: once all consumers of this component have converted to use updateData, remove undefined as possibility    
    queryData?: (queryParams?: IQueryParams) => void;
    exportable?: boolean; 
}

export type SortOrder = 'ascn' | 'desc';

/**
 * @returns a sortable table for the given data
 */
const SortableTable = ({ dataSelector, headers, queryState, renderDataCard, renderDataRow, queryData, exportable }: ISortableTableProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const maybePage = searchParams.get('page');
    const maybeReverse = searchParams.get('reverse');
    const maybeSearch = searchParams.get('search');
    const maybeSort = searchParams.get('sort');

    const data = useAppSelector(dataSelector);

    const isDesktop: boolean = useDesktopView();

    const submitSearch = () => {
        const maybePageNumber = maybePage ? parseInt(maybePage) : 1;

        const queryParams: IQueryParams = {
            page: !isNaN(maybePageNumber) ? maybePageNumber : 1,
            reverse: maybeReverse === 'true' ? true : undefined,
            search: maybeSearch ? maybeSearch : undefined,
            sort: maybeSort ? maybeSort : undefined
        };

        if (queryData) {
            queryData(queryParams)
        }
    };

    useEffect(() => {
        submitSearch();
    }, [maybePage, maybeReverse, maybeSearch, maybeSort])

    const updatePagination = (page: number | string | undefined) => {
        if (queryData && page !== undefined) {
            searchParams.set('page', page.toString());
            setSearchParams(searchParams);
        }
    };

    return (
        <>
            {
                queryData &&
                <QueryBar
                    sortOptions={headers.filter(h => h.sortable)}
                    queryState={queryState}
                    exportable={exportable}
                />
            }

            {
                isDesktop &&
                    <Table.Container>
                    <Table>
                        <Table.Header>
                            <Table.HeaderRow>
                                {headers.map(hdr => {
                                    return (
                                        <Table.HeaderCell key={hdr.key}>{hdr.text}</Table.HeaderCell>
                                    )}
                                )}
                            </Table.HeaderRow>
                        </Table.Header>
                    <Table.Body>
                        { data?.map(dataItem => renderDataRow(dataItem)) }
                    </Table.Body>
                    </Table>
                    </Table.Container>
            }

            {
                !isDesktop &&
                    <>
                        { data?.map(dataItem => renderDataCard(dataItem))}
                    </>
            }


            {  (queryState?.totalPages && queryState.totalPages > 1 ) && 
                <Pagination 
                    activePage={queryState.page}
                    onPageChange={(e,{activePage}) => updatePagination(activePage)}
                    totalPages={queryState.totalPages}
                    floated='right'
                />
            }
        </>
    )
};

export default SortableTable;
