import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AxiosError } from "axios";
import { getUser } from "../app/apis/subRatingsAPI";
import { IErrorState, IUserResponse } from "../app/interfaces/IApiResponses";
import { IUser } from "../app/interfaces/IUser";
import { RootState } from "../app/store";
import { loginThunk, logoutThunk } from "./loginSlice";

export interface UserState {
    status: 'idle' | 'loading' | 'failed';
    data: IUser | null;
    error: undefined | IErrorState;
}

const initialState: UserState = {
    status: 'idle',
    data: null,
    error: undefined
};

export const getUserThunk = createAsyncThunk(
    'user/getUser',
    async (arg: undefined, { rejectWithValue }) => {
        try {
            return await getUser();
        } catch (err) {
            const error = err as AxiosError;
            return rejectWithValue({ reason: error.response?.data['reason'], status: error.response?.status });
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserError: (state) => {
            state.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.fulfilled, (state, action) => {
                const payload = action.payload as IUserResponse;
                state.data = payload;
            })
            .addCase(logoutThunk.fulfilled, (state, action) => {
                state.data = null;
            })
            .addCase(getUserThunk.pending, (state, action) => {
                state.status = 'loading';
            })
            .addCase(getUserThunk.fulfilled, (state, action) => {
                state.data = action.payload as IUser;
                state.status = 'idle';
            })
            .addCase(getUserThunk.rejected, (state, action) => {
                state.status = 'failed';
                const errorResponse = action?.payload as IErrorState;
                // Trying and failing to get a user with a 404 is how we know a user is not logged in.
                // So we don't need to do the same error message handling in that scenario.
                if (errorResponse.status !== 404) {
                    state.error = errorResponse;
                }
            });
    }
});

export const { clearUserError } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.data;
export const selectUserStatus = (state: RootState) => state.user.status;
export const selectUserState = (state: RootState) => state.user;

export default userSlice.reducer;
